/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Flex, Link, Stack, Text, VisuallyHiddenInput, } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { WiCloudUp } from 'react-icons/wi';
import { FILE_TYPE_WHITELIST } from '@/constants/app';
var DropZone = function (_a) {
    var onDocumentDrop = _a.onDocumentDrop;
    var onDragOver = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    var onDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files) {
            onDocumentDrop(e.dataTransfer.files);
        }
    };
    var fileInputEl = React.useRef(null);
    var onFileBrowseClick = function () {
        if (fileInputEl && fileInputEl.current) {
            fileInputEl.current.click();
        }
    };
    var onFileInputChange = function (e) {
        if (e.target.files) {
            onDocumentDrop(e.target.files);
        }
    };
    return (<Flex sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#eafafa',
        border: '2px dashed',
        borderColor: '#d6f5f5',
    }} onDrop={onDrop} onDragOver={onDragOver} data-testid="dropZone">
      <Stack direction="row" spacing={4} align="center">
        <Box as={WiCloudUp} sx={{ color: 'cta.primary.500', fontSize: '2.5rem' }}/>

        <Text sx={{ color: 'brand.grey.400' }}>
          <Trans i18nKey="evidence-upload/document:DROP_ZONE.LABEL">
            Drop a file here or
            <Link sx={{ color: 'cta.primary.500' }} onClick={onFileBrowseClick}>
              {'\t'}browse
            </Link>
          </Trans>
        </Text>
      </Stack>
      <VisuallyHiddenInput id="fileInput" type="file" data-testid="fileInput" ref={fileInputEl} onChange={onFileInputChange} multiple accept={FILE_TYPE_WHITELIST.join(',')}/>
    </Flex>);
};
export default DropZone;
