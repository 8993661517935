import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
export var BrandSpinnerIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'lg' : _b;
    return (<Spinner data-testid="spinner" thickness="4px" speed="0.65s" emptyColor="brand.grey.50" color="brand.blue.900" size={size}/>);
};
var LoadingIndicator = function () { return (<Flex data-testid="loadingIndicator" sx={{
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: 'rgba(255, 255, 255, .5)',
    height: '100%',
    zIndex: 10,
}}>
    <BrandSpinnerIcon />
  </Flex>); };
export default LoadingIndicator;
