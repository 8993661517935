import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaInfoCircle } from 'react-icons/fa';
import { useHistory } from 'react-router';
import Person from '@/public/static/img/Person.png';
import LogOut from '@/public/static/img/Logout.png';
import { removeUserQuery } from '@/domain/User/UserService';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
var UserMenu = function () {
    var t = useTranslation(['tenants']).t;
    var user = useAuth0().user;
    var history = useHistory();
    var _a = useTenantContext(), setTenant = _a.setTenant, tenant = _a.tenant, tenants = _a.tenants;
    var _b = React.useState(''), changeTenant = _b[0], setChangeTenant = _b[1];
    return (<Box data-testid="user-menu">
      {tenants.length !== 0 && changeTenant !== tenant && changeTenant !== '' && (<AlertDialog onCloseCallback={function () {
        setTenant(changeTenant);
        history.push('/');
    }} color="brand.status.info" headerText={t(['tenants:DIALOG.HEADER.SWITCH'])} bodyText={[t(['tenants:DIALOG.BODY.SWITCH_TENANTS'])]} ctaText={t(['tenants:DIALOG.CTA.YES'])} icon={FaInfoCircle} secondaryButtonText={t(['tenants:DIALOG.CTA.NO'])} secondaryCallback={function () { return setChangeTenant(tenant); }}/>)}
      <Flex>
        <Menu>
          <MenuButton as={Button} variant="menuButton" rightIcon={<FaAngleDown />}>
            <Flex>
              <Image src={Person} sx={{ marginRight: 3 }}/>
              {user === null || user === void 0 ? void 0 : user.email}
            </Flex>
          </MenuButton>
          <MenuList>
            {tenants.length > 1 &&
        tenants.map(function (tenantItem, index) { return (<MenuItem data-testid={index} key={tenantItem} isDisabled={tenantItem === tenant} onClick={function () {
            setChangeTenant(tenantItem);
        }}>
                  {tenantItem}
                </MenuItem>); })}
            <MenuItem onClick={function () {
        removeUserQuery();
        history.push('/logout');
    }}>
              {t('common:LOGOUT')}
            </MenuItem>
          </MenuList>
        </Menu>
        <div style={{
        paddingTop: '10px',
    }}>
          <Image src={LogOut} sx={{
        width: '16px',
        height: '16px',
    }}/>
        </div>
      </Flex>
    </Box>);
};
export default UserMenu;
