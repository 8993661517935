var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
import { MimeType } from '@/domain/Policy/Policy';
export var BASE_API = '/api/';
export var TENANCY_SERVICE_API = 'tenancy-service';
export var POLICY_SERVICE_API = 'policy-service';
export var RISK_SERVICE_API = 'risk-service';
export var FILE_TYPE_WHITELIST = __spreadArrays(Object.values(MimeType).filter(function (v) { return typeof v === 'string'; }));
export var DEBOUNCE_DELAY = 600;
export var RISKS_IDENTIFIED_PAGE_SIZE = 2000;
