import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingIndicator from '@/components/LoadingIndicator/LoadingIndicator';
var LogoutPage = function () {
    var logout = useAuth0().logout;
    React.useEffect(function () {
        logout({ returnTo: window.location.origin });
    }, [logout]);
    return <LoadingIndicator />;
};
export default LogoutPage;
