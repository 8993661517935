var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import AppLayout from '@/components/Layout/AppLayout/AppLayout';
import LogoutPage from './LogoutPage';
import PolicyManagementIndexPage from './policy-management/PolicyManagementPage';
import CreatePolicyPage from './policy-management/create/CreatePolicyPage';
import PolicySummaryPage from './policy-management/view/[policyReference]/PolicySummaryPage/PolicySummaryPage';
import ErrorPage from './ErrorPage';
import InjectAxiosInterceptors from '@/libs/axiosConfig/interceptors/InjectAxiosInterceptors';
import NotFoundPage from './NotFoundPage';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import LoadingIndicator from '@/components/LoadingIndicator/LoadingIndicator';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import { getAllTenantForUser } from '@/domain/Tenancy/TenancyService';
var ProtectedRoute = function (_a) {
    var component = _a.component, args = __rest(_a, ["component"]);
    return (<Route component={withAuthenticationRequired(component)} {...args}/>);
};
var IndexPage = function () {
    var _a = useAuth0(), isLoading = _a.isLoading, loginWithRedirect = _a.loginWithRedirect, user = _a.user, isAuthenticated = _a.isAuthenticated, getAccessTokenSilently = _a.getAccessTokenSilently;
    var _b = useTenantContext(), setTenant = _b.setTenant, tenant = _b.tenant, setTenants = _b.setTenants;
    var _c = useAuthToken(), setToken = _c.setToken, token = _c.token;
    React.useEffect(function () {
        function fetchTenantToken() {
            return __awaiter(this, void 0, Promise, function () {
                var token, decoded, username, allTenants;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getAccessTokenSilently()];
                        case 1:
                            token = _a.sent();
                            decoded = jwtDecode(token);
                            username = decoded["https://underwriteme.co.uk/username"];
                            return [4 /*yield*/, getAllTenantForUser({
                                    token: token,
                                    username: username,
                                })];
                        case 2:
                            allTenants = _a.sent();
                            setToken(token);
                            setTenants(allTenants);
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (isAuthenticated) {
            fetchTenantToken();
        }
    }, [getAccessTokenSilently, isAuthenticated, setTenant, setToken]);
    React.useEffect(function () {
        (function login() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!isLoading && typeof user === 'undefined') {
                        loginWithRedirect();
                    }
                    return [2 /*return*/];
                });
            });
        })();
    }, [user, isLoading, loginWithRedirect]);
    return (<Router>
      <InjectAxiosInterceptors />
      <AppLayout>
        {tenant === '' || token === '' || isLoading ? (<LoadingIndicator />) : (<Switch>
            <ProtectedRoute exact path="/error" component={ErrorPage}/>
            <ProtectedRoute exact path="/logout" component={LogoutPage}/>
            <ProtectedRoute exact path="/policy-management" component={PolicyManagementIndexPage}/>
            <ProtectedRoute exact path="/policy-management/create" component={CreatePolicyPage}/>
            <ProtectedRoute exact path="/policy-management/view/:policyReference/summary" component={PolicySummaryPage}/>
            <Route exact path="/" render={function () {
        return user && isAuthenticated ? (<Redirect to="/policy-management"/>) : (<></>);
    }}/>
            <Route component={NotFoundPage}/>
          </Switch>)}
      </AppLayout>
    </Router>);
};
export default IndexPage;
