var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Image, Spacer, Text, useToast, } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import nextId from 'react-id-generator';
import { useTranslation } from 'react-i18next';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import { saveDisagreedEvidence, saveFeedback, } from '@/domain/Misrep/MisrepService';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import FullReport from '@/pages/policy-management/view/[policyReference]/FullReport/FullReport';
import dateImage from '@/public/static/img/date.png';
import { MisRepContext } from '../MisRepReport';
import FeedbackDialog from '@/components/AlertDialog/Disagreement/FeedbackDialog';
var MisRepItem = function (_a) {
    var policyReference = _a.policyReference, runId = _a.runId, misrepResult = _a.misrepResult, misrepLabelColour = _a.misrepLabelColour, misrepLabel = _a.misrepLabel;
    var tenant = useTenantContext().tenant;
    var token = useAuthToken().token;
    var t = useTranslation(['policy-management/misrep']).t;
    var _b = useState(), disagreedEvidence = _b[0], setDisagreedEvidence = _b[1];
    var _c = useState(), disagreedQuestion = _c[0], setDisagreedQuestion = _c[1];
    var fetchAndSetResults = useContext(MisRepContext).fetchAndSetResults;
    var toast = useToast();
    var sortEvidencesByDate = function (foundedEvidences) {
        return __spreadArrays(foundedEvidences).sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
        });
    };
    var _d = useState(sortEvidencesByDate(misrepResult.foundedEvidences).filter(function (evidence) { return !evidence.disagreed; })), foundEvidences = _d[0], setFoundEvidences = _d[1];
    var getAnswer = function (misRepResult) {
        if (misRepResult.question.answerType === 'Boolean') {
            return misRepResult.question.answerValue === 'true' ? 'Yes' : 'No';
        }
        return misRepResult.question.answerValue;
    };
    var defaultToastFeedback = function (question, feedback) {
        return {
            position: 'bottom-right',
            title: 'Feedback Recieved',
            description: (<>
          <b>Question</b>: {question}
          <br />
          <b>Feedback</b>: {feedback}
        </>),
            status: 'success',
            duration: 4000,
        };
    };
    /* eslint-disable no-param-reassign */
    var disagreeEvidence = function (evidence, evidences, feedbackReason) { return __awaiter(void 0, void 0, void 0, function () {
        var disagreedEvidence;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    evidence.disagreed = true;
                    disagreedEvidence = {
                        feedbackReason: feedbackReason,
                        foundedEvidence: evidence,
                        questionId: misrepResult.question.id,
                    };
                    return [4 /*yield*/, saveDisagreedEvidence(disagreedEvidence, policyReference, runId, token, tenant)];
                case 1:
                    _a.sent();
                    toast(defaultToastFeedback(misrepResult.question.question, feedbackReason));
                    if (!(evidences.filter(function (result) { return !result.disagreed; }).length === 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchAndSetResults()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setFoundEvidences(sortEvidencesByDate(evidences).filter(function (evidence) { return !evidence.disagreed; }));
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var disagreeQuestion = function (question, feedbackReason) { return __awaiter(void 0, void 0, void 0, function () {
        var feedbackRequest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    feedbackRequest = {
                        feedbackReason: feedbackReason,
                    };
                    return [4 /*yield*/, saveFeedback(feedbackRequest, policyReference, runId, question.id, token, tenant)];
                case 1:
                    _a.sent();
                    toast(defaultToastFeedback(misrepResult.question.question, feedbackReason));
                    return [2 /*return*/];
            }
        });
    }); };
    var isEvidenceEmpty = foundEvidences.length === 0;
    /* eslint-enable no-param-reassign */
    function getPreText(evidence) {
        var maxCharsToShow = 50;
        var lengthOfString = 100;
        var highlighted = evidence.text;
        var indexOfHighlighted = evidence.sentence.indexOf(highlighted);
        var preHighlight = indexOfHighlighted - maxCharsToShow > 0
            ? indexOfHighlighted - maxCharsToShow
            : 0;
        return evidence.sentence.length > lengthOfString
            ? "..." + evidence.sentence.substr(preHighlight, indexOfHighlighted - preHighlight)
            : evidence.sentence.substring(0, indexOfHighlighted);
    }
    function getPostText(evidence) {
        var highlighted = evidence.text;
        var postHighlight = evidence.sentence.indexOf(highlighted) + highlighted.length;
        var maxCharsToShow = 50;
        var lengthOfString = 100;
        return evidence.sentence.length > lengthOfString
            ? evidence.sentence.substring(postHighlight, postHighlight + maxCharsToShow) + "..."
            : evidence.sentence.substring(postHighlight, evidence.sentence.length);
    }
    var getAccordionButtonStyles = function () {
        if (isEvidenceEmpty) {
            return {
                cursor: 'default',
                _hover: {},
                _focus: {
                    outline: 'none',
                    boxShadow: 'none',
                },
            };
        }
        return {};
    };
    var getAccordionIconStyles = function () {
        if (isEvidenceEmpty) {
            return {
                color: 'white',
            };
        }
        return {};
    };
    return (<Box sx={{
        paddingTop: '5px',
        paddingBottom: '5px',
        borderTop: 'dotted',
        borderTopColor: 'brand.grey.50',
        borderTopWidth: '1px',
    }}>
      <Accordion allowToggle sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
    }}>
        <AccordionItem>
          <AccordionButton data-testid={"question-accordian-button-" + misrepResult.question.id} sx={getAccordionButtonStyles()}>
            <Box as="span" flex="10" textAlign="left">
              <Flex sx={{
        flexDirection: 'row',
    }}>
                <Text as="b" fontSize="md">
                  {misrepResult.question.question}
                </Text>
              </Flex>
              <Flex sx={{
        flexDirection: 'row',
    }}>
                <Text>&lsquo;</Text>
                <Text>{getAnswer(misrepResult)}</Text>
                <Text>&rsquo;</Text>
              </Flex>
            </Box>
            {isEvidenceEmpty && (<Box as="span" flex="1" textAlign="left" marginRight={5}>
                <Button data-testid={"disagreeButton-" + misrepResult.question.id} variant="link" colorScheme="teal" textTransform="none" onClick={function () {
        setDisagreedQuestion(misrepResult.question);
    }} testid="btnId">
                  Disagree?
                </Button>
              </Box>)}
            <Box borderRadius="md" color={misrepLabelColour + ".900"} backgroundColor={misrepLabelColour + ".100"} px={8} mr={8} h={7} minW="150px" maxW="150px">
              <Text as="b" fontSize="xs">
                {misrepLabel}
              </Text>
            </Box>

            <AccordionIcon sx={getAccordionIconStyles()}/>
          </AccordionButton>

          {!isEvidenceEmpty && (<AccordionPanel pb={4}>
              <Text>Latest records found in the evidence document:</Text>
              <br />
              {foundEvidences.map(function (misRepQuestionInstance) { return (<Box key={nextId()} sx={{
        borderColor: 'brand.grey.50',
        borderWidth: '1px',
        margin: '5px 150px 0px 150px',
        padding: '5px 10px 5px 5px',
        borderRadius: 'md',
    }}>
                  <Flex flexDirection="row">
                    <Box paddingLeft="10px">
                      <Image src={dateImage} h="16px" w="16px"/>
                    </Box>
                    <Box paddingLeft="10px">
                      <Text>{misRepQuestionInstance.date}</Text>
                    </Box>
                    <Spacer />
                    <Box sx={{
        paddingRight: '5px',
    }}>
                      <Button data-testid={"disagreeButton-" + misRepQuestionInstance.normalisationId} variant="link" colorScheme="teal" textTransform="none" onClick={function () {
        setDisagreedEvidence(misRepQuestionInstance);
    }} testid="btnId">
                        Disagree?
                      </Button>
                    </Box>
                  </Flex>
                  <Text data-testid="sentence">
                    {getPreText(misRepQuestionInstance)}
                    <mark>{misRepQuestionInstance.text}</mark>
                    {getPostText(misRepQuestionInstance)}
                  </Text>

                  <Box key={nextId()}>
                    <Accordion allowToggle data-testid="full-report" key={nextId()} sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
    }}>
                      <AccordionItem isFocusable>
                        {function (_a) {
        var isExpanded = _a.isExpanded;
        return (<>
                            <h2>
                              <AccordionButton>
                                <Box>
                                  <Text as="b" fontSize="xs">
                                    View in report
                                  </Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>

                            <AccordionPanel pb={4}>
                              {isExpanded ? (<FullReport policyReference={policyReference} runId={runId} token={token} nerSpanBegin={misRepQuestionInstance.textSpanBegin} nerSpanEnd={misRepQuestionInstance.textSpanEnd}/>) : null}
                            </AccordionPanel>
                          </>);
    }}
                      </AccordionItem>
                    </Accordion>
                  </Box>
                </Box>); })}
            </AccordionPanel>)}
        </AccordionItem>
      </Accordion>
      <Box data-testid="disclosure-disagreement-dialog">
        <FeedbackDialog isOpen={disagreedEvidence !== undefined} onCloseFunction={function () {
        setDisagreedEvidence(undefined);
    }} primaryOnClick={function (feedbackOption) {
        if (disagreedEvidence) {
            disagreeEvidence(disagreedEvidence, misrepResult.foundedEvidences, feedbackOption);
        }
    }} headerText={t('ALERT_DIALOG.DISCLOSURE_DISAGREEMENT.HEADER.TEXT')} bodyText={[t('ALERT_DIALOG.DISCLOSURE_DISAGREEMENT.BODY.TEXT')]} ctaText={t('ALERT_DIALOG.DISCLOSURE_DISAGREEMENT.BODY.BUTTON.TEXT')} radioButtonOptions={[
        'Wrongly identified entry',
        'Incorrect date linked',
        'Negation not picked up',
        'Other',
    ]}/>
      </Box>
      <Box data-testid="question-disagreement-dialog">
        <FeedbackDialog isOpen={disagreedQuestion !== undefined} onCloseFunction={function () {
        setDisagreedQuestion(undefined);
    }} primaryOnClick={function (feedbackOption) {
        if (disagreedQuestion) {
            disagreeQuestion(disagreedQuestion, feedbackOption);
        }
    }} headerText={t('ALERT_DIALOG.QUESTION_DISAGREEMENT.HEADER.TEXT')} bodyText={[t('ALERT_DIALOG.QUESTION_DISAGREEMENT.BODY.TEXT')]} ctaText={t('ALERT_DIALOG.QUESTION_DISAGREEMENT.BODY.BUTTON.TEXT')} radioButtonOptions={['Misrep should of been found', 'Other']}/>
      </Box>
    </Box>);
};
export default MisRepItem;
