import { extendTheme } from '@chakra-ui/react';
var textMiningTheme = extendTheme({
    fonts: {
        body: '"Helvetica Neue", Helvetica, Lato, sans-serif',
    },
    colors: {
        brand: {
            grey: {
                50: '#F4F8F9',
                100: '#d9d9d9',
                200: '#bfbfbf',
                300: '#a6a6a6',
                400: '#8c8c8c',
                500: '#737373',
                600: '#595959',
                700: '#404040',
                800: '#262626',
                900: '#0d0d0d',
            },
            blue: {
                50: '#eaf6fe',
                100: '#c9dce9',
                200: '#a7c2d6',
                300: '#84a8c7',
                400: '#628cb7',
                500: '#49709d',
                600: '#38557b',
                700: '#283c58',
                800: '#1A202C',
                900: '#050d16',
            },
            red: {
                50: '#ffe2ec',
                100: '#ff646433',
                200: '#fc839f',
                300: '#f95278',
                400: '#f62252',
                500: '#dd0939',
                600: '#ad032c',
                700: '#7c001e',
                800: '#4d0012',
                900: '#ff6464',
            },
            green: {
                50: '#e2fde9',
                100: '#99BF6333',
                200: '#96e7a9',
                300: '#6edd87',
                400: '#46d366',
                500: '#2cb94d',
                600: '#1f903b',
                700: '#146729',
                800: '#073f17',
                900: '#8AB250',
            },
            yellow: {
                50: '#fff9da',
                100: '#ffecad',
                200: '#ffdf7d',
                300: '#ffd24b',
                400: '#ffc61a',
                500: '#e6ac00',
                600: '#b38600',
                700: '#806000',
                800: '#4e3900',
                900: '#1d1300',
            },
            orange: {
                50: '#ffeedd',
                100: '#fed2b3',
                200: '#f9b486',
                300: '#f49757',
                400: '#f07a29',
                500: '#d6600f',
                600: '#a84b0a',
                700: '#783405',
                800: '#491f00',
                900: '#1f0800',
            },
            teal: {
                50: '#dff9ff',
                100: '#dcf6f7',
                200: '#a0d5db',
                300: '#3CC3CC66',
                400: '#5bb1bd',
                500: '#4298a4',
                600: '#307680',
                700: '#1f555c',
                800: '#0a3439',
                900: '#26BBC5',
            },
            status: {
                success: '#2cb94d',
                error: '#dd0939',
                warning: '#e6ac00',
                info: '#628cb7',
            },
        },
        cta: {
            primary: {
                500: '#4298a4',
                600: '#307680',
            },
            secondary: {
                100: '#809DBF33',
                500: '#f07a29',
                600: '#d6610f',
                900: '#809DBF',
            },
        },
        components: {
            table: {
                head: '#262626',
                alt: '#FBFBFB',
                border: '#262626',
            },
        },
    },
    components: {
        Button: {
            baseStyle: {
                textTransform: 'uppercase',
                fontWeight: 'semibold',
                _focus: { boxShadow: 'none' },
                _active: { boxShadow: 'none' },
            },
            variants: {
                menuButton: {
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '.9rem',
                },
            },
        },
        Menu: {
            parts: ['list', 'item'],
            baseStyle: {
                list: {
                    py: 0,
                },
                item: {
                    py: 3,
                    fontWeight: 'bold',
                    color: 'brand.grey.800',
                    fontSize: '.8em',
                    borderBottom: '1px',
                    borderBottomColor: 'brand.grey.50',
                    textTransform: 'uppercase',
                    _hover: { backgroundColor: 'brand.grey.50' },
                    _focus: { backgroundColor: 'brand.grey.50' },
                    _last: { borderBottom: 0 },
                },
            },
        },
        Tabs: {
            parts: ['tab', 'tablist', 'tabpanels', 'tabpanel'],
            baseStyle: {
                tab: {
                    backgroundColor: 'brand.blue.800',
                    borderRadius: '.3rem .3rem 0 0',
                    fontWeight: 'bold',
                    color: '#fff',
                    textTransform: 'uppercase',
                    marginLeft: '.5rem',
                    _focus: {
                        backgroundColor: 'brand.teal.100',
                        color: 'brand.blue.800',
                        boxShadow: 'none',
                        borderColor: 'brand.teal.100',
                    },
                    _selected: {
                        backgroundColor: 'brand.teal.100',
                        color: 'brand.blue.800',
                        borderColor: 'brand.teal.100',
                    },
                },
                tabpanel: {
                    backgroundColor: '#fff',
                },
            },
            variants: {
                'policy-summary-tabs': {
                    backgroundColor: '#fff',
                    tab: {
                        backgroundColor: '#fff',
                        borderRadius: '.3rem .3rem 0 0',
                        fontWeight: 'bold',
                        color: 'black',
                        textTransform: 'uppercase',
                        marginLeft: '.5rem',
                        _focus: {
                            backgroundColor: 'brand.teal.100',
                            color: 'brand.blue.800',
                            boxShadow: 'none',
                            borderColor: 'brand.teal.100',
                        },
                        _selected: {
                            backgroundColor: 'brand.teal.100',
                            color: 'brand.blue.800',
                            borderColor: 'brand.teal.100',
                            borderRadius: '4px',
                        },
                    },
                    tabpanel: {
                        backgroundColor: '#fff',
                    },
                    tablist: {
                        backgroundColor: '#fff',
                    },
                },
            },
        },
        Heading: {
            variants: {
                'page-header': {
                    textTransform: 'uppercase',
                    marginBottom: 8,
                    fontSize: '1.5rem',
                },
            },
        },
    },
});
export default textMiningTheme;
