var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { RISK_SERVICE_API, RISKS_IDENTIFIED_PAGE_SIZE } from '@/constants/app';
import axios from '@/libs/axiosConfig/axiosConfig';
import { makeQueryParams } from '@/libs/urlParams/urlParams';
export function getRunFileAnnotations(_a) {
    var _b = _a.runId, runId = _b === void 0 ? '' : _b, _c = _a.params, params = _c === void 0 ? '' : _c, _d = _a.filename, filename = _d === void 0 ? '' : _d, _e = _a.tenant, tenant = _e === void 0 ? '' : _e, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0: return [4 /*yield*/, axios.get(RISK_SERVICE_API + "/v2/tenant/" + tenant + "/runs/" + runId + "/files/" + filename + "/risks?" + params, {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_f.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getNonPagedRunFileAnnotations(_a) {
    var _b = _a.runId, runId = _b === void 0 ? '' : _b, _c = _a.filename, filename = _c === void 0 ? '' : _c, _d = _a.tenant, tenant = _d === void 0 ? '' : _d, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var params, _e, totalPages, firstPageNumber, firstPageContent, promises, page, nextParams, combinedPages;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    params = makeQueryParams({
                        page: 0,
                        size: RISKS_IDENTIFIED_PAGE_SIZE,
                    });
                    return [4 /*yield*/, getRunFileAnnotations({ runId: runId, params: params, filename: filename, tenant: tenant, token: token })];
                case 1:
                    _e = _f.sent(), totalPages = _e.totalPages, firstPageNumber = _e.number, firstPageContent = _e.content;
                    promises = [];
                    for (page = firstPageNumber + 1; page < totalPages; page += 1) {
                        nextParams = makeQueryParams({
                            page: page,
                            size: RISKS_IDENTIFIED_PAGE_SIZE,
                        });
                        promises.push(getRunFileAnnotations({
                            runId: runId,
                            params: nextParams,
                            filename: filename,
                            tenant: tenant,
                            token: token,
                        }));
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 2:
                    combinedPages = (_f.sent())
                        .map(function (x) { return x.content; })
                        .flatMap(function (x) { return x; })
                        .concat(firstPageContent);
                    return [2 /*return*/, {
                            content: combinedPages,
                            number: 0,
                            totalPages: 1,
                            totalElements: combinedPages.length,
                        }];
            }
        });
    });
}
