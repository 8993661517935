/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_API } from '@/constants/app';
import { onRequestFulfilled, onResponseError, } from '@/libs/axiosConfig/interceptors/interceptors';
var http = axios.create({
    baseURL: BASE_API,
});
export var setupInterceptors = function (history) {
    http.interceptors.request.use(function (value) {
        return onRequestFulfilled(value);
    }, function (error) {
        return Promise.reject(error);
    });
    http.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        onResponseError(error, history);
    });
};
export default http;
