/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControl, FormLabel, Input, Center, InputGroup, InputRightElement, Radio, RadioGroup, VStack, Select, HStack, FormErrorMessage, Icon, Heading, } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Product, Sex } from '@/domain/Policy/Policy';
import { BrandSpinnerIcon } from '@/components/LoadingIndicator/LoadingIndicator';
import schema from './create.schema';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import useGetPolicyByReference from '@/domain/Policy/hooks/useGetPolicyByReference/useGetPolicyByReference';
import useDebounce from '@/hooks/useDebounce/useDebounce';
var PolicyDetailsForm = function (_a) {
    var formValues = _a.formValues, policyReference = _a.policyReference, setPolicyReference = _a.setPolicyReference, formikRef = _a.formikRef;
    var t = useTranslation([
        'policy-management/create',
        'policy-management/appform',
    ]).t;
    var tenant = useTenantContext().tenant;
    var token = useAuthToken().token;
    var _b = useGetPolicyByReference(policyReference, tenant, token), data = _b.data, error = _b.error, isLoading = _b.isLoading, refetch = _b.refetch;
    var isNotFound = function (error) {
        return (error === null || error === void 0 ? void 0 : error.response.status) === 404;
    };
    var debouncedPolicyReference = useDebounce(policyReference);
    var determinePolicyRefIcon = function (form) {
        if (isLoading) {
            return <BrandSpinnerIcon size="sm"/>;
        }
        if (data) {
            return (<Icon as={FaTimes} color="brand.status.error" data-testid="policy-ref-found"/>);
        }
        if (isNotFound(error) && !form.errors.policyReference) {
            return (<Icon as={FaCheck} color="brand.status.success" data-testid="policy-ref-available"/>);
        }
        return null;
    };
    React.useEffect(function () {
        if (debouncedPolicyReference.length >= 3) {
            refetch();
        }
    }, [debouncedPolicyReference, refetch]);
    return (<>
      <Heading as="h1" variant="page-header">
        <Center> {t('common:POLICY_DETAILS')} </Center>
      </Heading>
      <Center sx={{ height: '50px' }}>
        {t('policy-management/create:FORM.HEADER.INFO')}
      </Center>
      <Formik innerRef={formikRef} initialValues={formValues} validationSchema={schema} onSubmit={function () { }}>
        {function () { return (<Form id="policy-details" data-testid="form" noValidate>
            <VStack align="center" spacing={5}>
              <Center sx={{ w: '40%' }}>
                <Field name="policyReference" validate={function (value) {
        setPolicyReference(value);
        if (data) {
            return t('policy-management/create:FORM.ERROR.POLICY_REFERENCE.EXISTS');
        }
        return null;
    }}>
                  {function (_a) {
        var field = _a.field, form = _a.form;
        return (<FormControl id="policyReference" isInvalid={form.errors.policyReference &&
            form.touched.policyReference} isRequired sx={{
            mb: 1,
            align: 'center',
        }}>
                      <FormLabel htmlFor="policyReference">
                        {t('policy-management/create:FORM.LABEL.POLICY_REFERENCE')}
                      </FormLabel>
                      <InputGroup>
                        <Input data-testid="policyReference" {...field} backgroundColor="white"/>
                        <InputRightElement sx={{
            paddingRight: 0,
        }}>
                          {determinePolicyRefIcon(form)}
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {t(form.errors.policyReference)}
                      </FormErrorMessage>
                    </FormControl>);
    }}
                </Field>
              </Center>
              <Center sx={{ width: '40%' }}>
                <Field name="product">
                  {function (_a) {
        var field = _a.field;
        return (<FormControl id="productGroup" isRequired sx={{ mb: 4 }}>
                      <FormLabel htmlFor="productGroup">
                        {t('policy-management/create:FORM.LABEL.PRODUCT_APPLIED_FOR')}
                      </FormLabel>
                      <Select data-testid="product" placeholder="Select option" backgroundColor="white" {...field}>
                        <option value={Product.LIFE}>{Product.LIFE}</option>
                        <option value={Product.CI}>{Product.CI}</option>
                      </Select>
                    </FormControl>);
    }}
                </Field>
              </Center>
              <HStack spacing={40} width="40%">
                <Field name="age">
                  {function (_a) {
        var field = _a.field, form = _a.form;
        return (<FormControl id="age" isInvalid={form.errors.age && form.touched.age} sx={{
            mb: 1,
        }}>
                      <FormLabel htmlFor="age">
                        {t('policy-management/create:FORM.LABEL.AGE')}
                      </FormLabel>
                      <Input data-testid="age" {...field} backgroundColor="white" align="left"/>
                      <FormErrorMessage>{t(form.errors.age)}</FormErrorMessage>
                    </FormControl>);
    }}
                </Field>

                <Field name="sex">
                  {function (_a) {
        var field = _a.field, form = _a.form;
        return (<FormControl id="sexGroup" sx={{
            mb: 1,
        }}>
                      <FormLabel htmlFor="sexGroup">
                        {t('policy-management/create:FORM.LABEL.SEX')}
                      </FormLabel>
                      <RadioGroup {...field} defaultValue={formValues.sex} onChange={function (val) {
            return form.setFieldValue(field.name, val);
        }}>
                        <HStack sx={{
            ml: 1,
        }}>
                          <Radio value={Sex.MALE} colorScheme="brand.blue">
                            {t('policy-management/create:FORM.LABEL.MALE')}
                          </Radio>
                          <Radio value={Sex.FEMALE} colorScheme="brand.blue">
                            {t('policy-management/create:FORM.LABEL.FEMALE')}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </FormControl>);
    }}
                </Field>
              </HStack>

              <HStack spacing={30} width="40%">
                <Field name="applicationDate">
                  {function (_a) {
        var form = _a.form, field = _a.field;
        return (<FormControl id="applicationDateGroup" isRequired isInvalid={form.errors.applicationDate &&
            form.touched.applicationDate}>
                      <FormLabel htmlFor="applicationDateGroup">
                        {t('policy-management/create:FORM.LABEL.APPLICATION_DATE')}
                      </FormLabel>
                      <Input data-testid="applicationDate" placeholder="Select Date" size="md" type="date" backgroundColor="white" {...field}/>
                      <FormErrorMessage>
                        {t(form.errors.applicationDate)}
                      </FormErrorMessage>
                    </FormControl>);
    }}
                </Field>
              </HStack>
            </VStack>
          </Form>); }}
      </Formik>
    </>);
};
export default PolicyDetailsForm;
