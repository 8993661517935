import React from 'react';
import { VStack, Button, AlertDialog as ChakraAlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Text, HStack, Box, } from '@chakra-ui/react';
var AlertDialog = function (_a) {
    var onCloseCallback = _a.onCloseCallback, color = _a.color, headerText = _a.headerText, bodyText = _a.bodyText, ctaText = _a.ctaText, icon = _a.icon, secondaryButtonText = _a.secondaryButtonText, secondaryCallback = _a.secondaryCallback;
    var _b = React.useState(!!headerText), isOpen = _b[0], setIsOpen = _b[1];
    var onClose = function () { return setIsOpen(false); };
    var cancelRef = React.useRef(null);
    return (<ChakraAlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <HStack>
              <Box as={icon} color={color} sx={{
        fontSize: '1.5rem',
    }}/>
              <Text color={color}>{headerText}</Text>
            </HStack>
          </AlertDialogHeader>
          <AlertDialogBody>
            <VStack sx={{
        alignItems: 'flex-start',
        pl: '1',
    }}>
              {bodyText.map(function (item) { return (<Text key={item}>{item}</Text>); })}
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="cta.primary" onClick={function () {
        onCloseCallback();
        onClose();
    }}>
              {ctaText}
            </Button>
            {secondaryButtonText && secondaryCallback !== undefined && (<Button colorScheme="cta.secondary" onClick={function () {
        secondaryCallback();
        onClose();
    }}>
                {secondaryButtonText}
              </Button>)}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>);
};
export default AlertDialog;
