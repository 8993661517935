/* eslint-disable import/prefer-default-export */
import React from 'react';
import AuthContext from '@/domain/Auth/AuthContext';
export function useAuthToken() {
    var context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('AuthContext must be used within <App />');
    }
    return context;
}
