import { Flex, Image, Spacer } from '@chakra-ui/react';
import React from 'react';
import UserMenu from '@/domain/User/UserMenu/UserMenu';
import Logo from '@/public/static/img/AssessMe.png';
export var menuItems = [
    {
        href: '/policy-management',
        translationKey: 'POLICY_MANAGEMENT',
        subMenu: [
            {
                href: '/policy-management',
                translationKey: 'VIEW_POLICY_RUNS',
            },
            {
                href: '/policy-management/create',
                translationKey: 'CREATE_POLICY',
            },
        ],
    },
    {
        href: '/evidence-upload',
        translationKey: 'EVIDENCE_UPLOAD',
    },
];
var AppHeader = function () {
    return (<>
      <Flex sx={{
        padding: '1rem 1rem',
        borderX: '1px',
        borderColor: 'brand.grey.50',
    }}>
        <a href="/">
          <Image src={Logo} alt="Assess Me from UnderwriteMe" sx={{
        fontWeight: '700',
        width: '108px',
        height: '40px',
        marginLeft: 2,
        marginRight: 16,
        color: 'White',
    }}/>
        </a>
        <Spacer />
        <UserMenu />
      </Flex>
    </>);
};
export default AppHeader;
