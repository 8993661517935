var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { AspectRatio, Box, Center, Flex, Heading, useToast, } from '@chakra-ui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropZone from '@/components/Document/DropZone/DropZone';
import UploadList from '@/components/Document/UploadList/UploadList';
import { FILE_TYPE_WHITELIST } from '@/constants/app';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import { PolicyDocumentUploadStatus, } from '@/domain/Policy/Policy';
import { doPolicyDocumentUpload } from '@/domain/Policy/PolicyService';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import usePrevious from '@/hooks/usePrevious/usePrevious';
var DocumentUploadComponent = function (_a) {
    var policyReference = _a.policyReference, onDocumentUpload = _a.onDocumentUpload, documentUploadList = _a.documentUploadList, setDocumentUploadList = _a.setDocumentUploadList, isSubmitting = _a.isSubmitting;
    var t = useTranslation(['common', 'toast', 'evidence-upload/document']).t;
    var tenant = useTenantContext().tenant;
    var token = useAuthToken().token;
    var _b = useState(true), isSubmitDisabled = _b[0], setIsSubmitDisabled = _b[1];
    var toast = useToast();
    var assignedPolicyRefs = useRef([]);
    var fileUploadList = useRef(null);
    var prevDocumentUploadList = usePrevious(documentUploadList);
    var onUploadEvent = useCallback(function (percentage, file, policyReference, isRejected) {
        setDocumentUploadList(function (prevState) {
            return __spreadArrays(prevState).map(function (document) {
                if (document.policyReference === policyReference &&
                    document.filename === file.name) {
                    return __assign(__assign({}, document), { progress: percentage, status: isRejected
                            ? PolicyDocumentUploadStatus.REJECTED
                            : percentage < 99
                                ? PolicyDocumentUploadStatus.IN_PROGRESS
                                : PolicyDocumentUploadStatus.FULFILLED });
                }
                return document;
            });
        });
    }, []);
    var renderDocumentUploadList = useCallback(function (fileList, policyRefs) {
        if (policyRefs === void 0) { policyRefs = []; }
        assignedPolicyRefs.current = policyRefs;
        var state = Array.from(fileList).map(function (file, index) {
            var name = file.name, lastModified = file.lastModified, type = file.type;
            return {
                status: PolicyDocumentUploadStatus.IN_PROGRESS,
                filename: name,
                progress: 0,
                lastModified: lastModified,
                type: type,
                policyReference: policyRefs.length
                    ? policyRefs[index]
                    : policyReference,
            };
        });
        setDocumentUploadList(function (prevState) { return __spreadArrays(prevState, state); });
    }, [policyReference]);
    var doUpload = useCallback(function (fileList) { return __awaiter(void 0, void 0, void 0, function () {
        var requests, _loop_1, i, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requests = [];
                    _loop_1 = function (i) {
                        var file = fileList.item(i);
                        var policyRef = policyReference;
                        var foundDocument = documentUploadList.find(function (document) {
                            return document.policyReference === policyRef &&
                                document.filename === file.name;
                        });
                        if (foundDocument) {
                            var policyReference_1 = foundDocument.policyReference;
                            requests.push(doPolicyDocumentUpload(policyReference_1, file, tenant, token, onUploadEvent));
                        }
                    };
                    for (i = 0; i < fileList.length; i += 1) {
                        _loop_1(i);
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.allSettled(requests)];
                case 2:
                    _a.sent();
                    setIsSubmitDisabled(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setIsSubmitDisabled(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [documentUploadList, onUploadEvent, policyReference, tenant, token]);
    var handleOnDocumentDrop = function (fileList) { return __awaiter(void 0, void 0, Promise, function () {
        var file, valid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(fileList.length > 1)) return [3 /*break*/, 1];
                    toast({
                        position: 'top-right',
                        title: 'Error',
                        description: 'Only one file allowed to be uploaded for a policy. Please try again with one file',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return [3 /*break*/, 5];
                case 1:
                    file = fileList[0];
                    if (!!FILE_TYPE_WHITELIST.includes(file.type)) return [3 /*break*/, 2];
                    toast({
                        position: 'top-right',
                        title: 'Error',
                        description: 'Invalid file type',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return [3 /*break*/, 5];
                case 2:
                    if (!(file.size === 0)) return [3 /*break*/, 3];
                    toast({
                        position: 'top-right',
                        title: 'Error',
                        description: 'File appears to be empty',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return [3 /*break*/, 5];
                case 3:
                    valid = new DataTransfer();
                    valid.items.add(file);
                    return [4 /*yield*/, onDocumentUpload()];
                case 4:
                    _a.sent();
                    fileUploadList.current = valid.files;
                    setIsSubmitDisabled(true);
                    renderDocumentUploadList(fileUploadList.current);
                    doUpload(fileUploadList.current);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (typeof prevDocumentUploadList !== 'undefined') {
            if (documentUploadList.length >
                prevDocumentUploadList.length) {
                doUpload(fileUploadList.current);
            }
        }
    }, [documentUploadList, prevDocumentUploadList, doUpload, fileUploadList]);
    return (<>
      <Heading as="h1" variant="page-header">
        <Center>{t('common:EVIDENCE')}</Center>
      </Heading>
      <Center sx={{ height: '50px' }}> </Center>

      {isSubmitDisabled ? (<Center h="100px">
          {t('evidence-upload/document:FORM.EVIDENCE.UPLOAD_EVIDENCE_MSG')}
        </Center>) : isSubmitting ? (<Center h="100px">
          {t('evidence-upload/document:FORM.EVIDENCE.UPLOADING_FILE_MSG')}
        </Center>) : (<Center h="100px">
          {t('evidence-upload/document:FORM.EVIDENCE.FILE_UPLOADED_MSG')}
        </Center>)}
      {isSubmitDisabled ? (<Flex>
          <AspectRatio sx={{ flex: 5 }} ratio={4} color="blue">
            <DropZone onDocumentDrop={handleOnDocumentDrop}/>
          </AspectRatio>
        </Flex>) : (<Flex>
          <AspectRatio sx={{ flex: 5 }} ratio={4} backgroundColor="gray.50">
            <Box sx={{ flex: '1.rem' }}>
              <UploadList documentList={documentUploadList}/>
            </Box>
          </AspectRatio>
        </Flex>)}
    </>);
};
export default DocumentUploadComponent;
