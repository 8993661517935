import { AnalyticsBrowser } from '@segment/analytics-next';
import React, { createContext, useContext } from 'react';
var MetricsContext = createContext(null);
export var MetricsProvider = function (_a) {
    var _b;
    var children = _a.children;
    var analytics = new AnalyticsBrowser();
    // eslint-disable-next-line no-underscore-dangle
    var writeSegmentKey = (_b = window._env_) === null || _b === void 0 ? void 0 : _b.SEGMENT_KEY;
    analytics.load({ writeKey: writeSegmentKey });
    return (<MetricsContext.Provider value={{ analytics: analytics }}>
      {children}
    </MetricsContext.Provider>);
};
export var useMetrics = function () { return useContext(MetricsContext); };
