/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { VStack, useRadioGroup } from '@chakra-ui/react';
import RadioCard from '@/components/Radio/RadioCard';
var RadioOptions = function (_a) {
    var options = _a.options, setSelectedOption = _a.setSelectedOption;
    var _b = useRadioGroup({
        name: 'radio-options',
        defaultValue: '',
        onChange: function (value) { return setSelectedOption(value); },
    }), getRootProps = _b.getRootProps, getRadioProps = _b.getRadioProps;
    var group = getRootProps();
    return (<VStack {...group}>
      {options.map(function (option) {
        var _a = getRadioProps({
            value: option.toString(),
        }), onChange = _a.onChange, isChecked = _a.isChecked;
        return (<RadioCard key={option} name={option} onChange={onChange} isChecked={isChecked} defaultValue={option}>
            {option}
          </RadioCard>);
    })}
    </VStack>);
};
export default RadioOptions;
