import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import nextId from 'react-id-generator';
function Stepper(_a) {
    var totalSteps = _a.totalSteps, currentStep = _a.currentStep;
    return (<Flex justifyContent="center">
      {Array.from({ length: totalSteps }).map(function (_, index) { return (<Box key={nextId()} m="10px" w="8px" h="8px" borderRadius="50%" bg={index === currentStep ? 'blue.500' : 'gray.200'} mx={2}/>); })}
    </Flex>);
}
export default Stepper;
