/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
var TMAuth0Provider = function (_a) {
    var children = _a.children;
    return (<Auth0Provider domain={window._env_.AUTH0_DOMAIN} clientId={window._env_.AUTH0_CLIENT_ID} redirectUri={window.location.origin} scope="openid email offline_access" audience={window._env_.AUTH0_API_AUDIENCE} maxAge={60 * 60 * 24} useRefreshTokens cacheLocation="localstorage">
      {children}
    </Auth0Provider>);
};
export default TMAuth0Provider;
