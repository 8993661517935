/* eslint-disable no-useless-escape */
import * as yup from 'yup';
export var ENQUIRY_ID_VALIDATION_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
var enquirySchema = yup.object().shape({
    enquiryId: yup
        .string()
        .nullable()
        .trim()
        .test('not valid format of enquiry id', 'policy-management/enquiry:FORM.ERROR.ENQUIRY_ID.FORMAT', function (value) {
        if (value === null || value === undefined || value === '') {
            return true;
        }
        return new RegExp(ENQUIRY_ID_VALIDATION_REGEX).test(value !== null && value !== void 0 ? value : '');
    }),
});
export default enquirySchema;
