import { Box, useRadio } from '@chakra-ui/react';
import React from 'react';
/* eslint-disable react/jsx-props-no-spreading */
var RadioCard = function (_a) {
    var name = _a.name, defaultValue = _a.defaultValue, onChange = _a.onChange, isChecked = _a.isChecked, children = _a.children;
    var _b = useRadio({
        name: name,
        value: defaultValue,
        onChange: onChange,
        isChecked: isChecked,
    }), getInputProps = _b.getInputProps, getCheckboxProps = _b.getCheckboxProps;
    var input = getInputProps();
    var checkbox = getCheckboxProps();
    return (<Box as="label" width="400px" height="40px">
      <input {...input}/>
      <Box {...checkbox} cursor="pointer" borderRadius="md" display="flex" alignItems="center" justifyContent="center" _checked={{
        bg: '#2D3849',
        color: 'white',
        borderColor: 'teal.600',
    }} px={20} py={2}>
        {children}
      </Box>
    </Box>);
};
export default RadioCard;
