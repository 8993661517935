import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    debug: false,
    detection: {
        order: ['querystring', 'navigator'],
        lookupQuerystring: 'locale',
        lookupFromPathIndex: 0,
    },
    fallbackLng: {
        default: ['en'],
    },
    defaultNS: 'common',
    supportedLngs: ['en'],
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
        wait: true,
    },
});
export default i18n;
