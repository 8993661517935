/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { render } from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { MetricsProvider } from '@/services/segment/metrics.provider';
import textMiningTheme from '@/theme/textMining';
import { TenantContextProvider } from '@/domain/Tenancy/TenantContext';
import i18n from '@/libs/i18n/i18n';
import Auth0Provider from '@/libs/auth0/Auth0Provider';
import IndexPage from './IndexPage';
import AuthContext from '@/domain/Auth/AuthContext';
var App = function () {
    useTranslation(['toast', 'errors']);
    var queryCache = new QueryClient();
    var _a = React.useState(''), token = _a[0], setToken = _a[1];
    return (<Auth0Provider>
      <MetricsProvider>
        <QueryClientProvider client={queryCache}>
          <ChakraProvider resetCSS theme={textMiningTheme}>
            <I18nextProvider i18n={i18n}>
              <AuthContext.Provider value={{ token: token, setToken: setToken }}>
                <TenantContextProvider>
                  <IndexPage />
                </TenantContextProvider>
              </AuthContext.Provider>
            </I18nextProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-left"/>
          </ChakraProvider>
        </QueryClientProvider>
      </MetricsProvider>
    </Auth0Provider>);
};
render(<App />, document.getElementById('app'));
