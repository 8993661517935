import { Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
var NotFoundPage = function () {
    var t = useTranslation(['errors']).t;
    return (<>
      <Text>{t(['errors:ERROR.PAGE.TITLE'])}</Text>
      <Link to="/">{t(['errors:ERROR.PAGE.HOME_LINK'])}</Link>
    </>);
};
export default NotFoundPage;
