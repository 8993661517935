/* eslint-disable no-useless-escape */
import * as yup from 'yup';
export var POLICY_REF_VALIDATION_REGEX = /^[a-zA-Z0-9]*$/;
var schema = yup.object().shape({
    policyReference: yup
        .string()
        .trim()
        .required('policy-management/create:FORM.ERROR.POLICY_REFERENCE.REQUIRED')
        .test('alphanumeric characters only', 'policy-management/create:FORM.ERROR.POLICY_REFERENCE.FORMAT', function (value) {
        return new RegExp(POLICY_REF_VALIDATION_REGEX).test(value !== null && value !== void 0 ? value : '');
    })
        .min(3, 'policy-management/create:FORM.ERROR.POLICY_REFERENCE.MIN_LENGTH')
        .max(256, 'policy-management/create:FORM.ERROR.POLICY_REFERENCE.MAX_LENGTH'),
    age: yup
        .string()
        .trim()
        .required('policy-management/create:FORM.ERROR.AGE.REQUIRED')
        .matches(/^([0-9]{1,3})$/i, {
        message: 'policy-management/create:FORM.ERROR.AGE.FORMAT',
    }),
    product: yup
        .string()
        .trim()
        .required('policy-management/create:FORM.ERROR.PRODUCT.REQUIRED'),
    applicationDate: yup
        .date()
        .required('policy-management/create:FORM.ERROR.APPLICATION_DATE.REQUIRED'),
});
export default schema;
