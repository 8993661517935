/* eslint-disable-next-line import/prefer-default-export */
export var RunStatus;
(function (RunStatus) {
    RunStatus[RunStatus["IN_PROGRESS"] = 'IN_PROGRESS'] = "IN_PROGRESS";
    RunStatus[RunStatus["PROCESSED"] = 'PROCESSED'] = "PROCESSED";
    RunStatus[RunStatus["ERROR"] = 'ERROR'] = "ERROR";
    RunStatus[RunStatus["MISREP"] = 'MISREP'] = "MISREP";
    RunStatus[RunStatus["PASSED"] = 'PASSED'] = "PASSED";
    RunStatus[RunStatus["FAILED"] = 'FAILED'] = "FAILED";
})(RunStatus || (RunStatus = {}));
export var RiskLevel;
(function (RiskLevel) {
    RiskLevel[RiskLevel["HIGH"] = 'High'] = "HIGH";
    RiskLevel[RiskLevel["MEDIUM"] = 'Medium'] = "MEDIUM";
    RiskLevel[RiskLevel["LOW"] = 'Low'] = "LOW";
    RiskLevel[RiskLevel["NONE"] = 'None'] = "NONE";
    RiskLevel[RiskLevel["UNKNOWN"] = 'Unknown'] = "UNKNOWN";
})(RiskLevel || (RiskLevel = {}));
export var ERisk;
(function (ERisk) {
    ERisk["GROUP"] = "group";
    ERisk["CATEGORY"] = "category";
    ERisk["UNNORMALISED_NAME"] = "unnormalisedName";
    ERisk["NORMALISED_NAME"] = "normalisedName";
    ERisk["DATE"] = "date";
    ERisk["LEVEL"] = "level";
    ERisk["RISK_SCORE"] = "riskScore";
    ERisk["LAB_RESULT"] = "labResult";
})(ERisk || (ERisk = {}));
