import { Box, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';
var DecisionComparisons = function (_a) {
    var productDecisionsComparisons = _a.productDecisionsComparisons;
    function showValues(values) {
        return values.map(function (value) { return (<div style={{ display: 'flex', justifyContent: 'left' }}>
        <span style={{
            marginRight: '5px',
            padding: '.1rem',
            backgroundColor: '#FFFFFF',
        }}>
          {value.type}
        </span>
        <span style={{
            backgroundColor: '#455A64',
            borderRadius: '.3rem',
            color: '#FFFFFF',
        }}>
          {value.values.toString()}
        </span>
      </div>); });
    }
    return (<>
      {productDecisionsComparisons !== undefined &&
        productDecisionsComparisons.length > 0 && (<Flex sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '2px',
        borderColor: '#E7E7E7',
        padding: '.5rem',
        borderRadius: '2rem',
    }}>
            <Box data-testid="product-decisions-title" sx={{
        textAlign: 'center',
        column: '1',
        padding: '10px',
        fontWeight: 'bold',
    }}>
              Product Level Decisions
            </Box>
            <Flex sx={{
        flexDirection: 'raw',
        justifyContent: 'center',
    }}>
              <Box sx={{
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
        column: '1',
        fontWeight: 'bold',
        justifyContent: 'raw',
    }}>
                {productDecisionsComparisons[0].name}
              </Box>
            </Flex>
            <Flex sx={{
        flexDirection: 'raw',
        padding: '10px',
    }}>
              <Flex sx={{
        padding: '10px',
        gap: '10px',
        borderRadius: '.8rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '60px',
        minHeight: '60px',
        backgroundColor: '#E9EEF1',
        marginRight: '10px',
    }}>
                <Box data-testid="product-comparison-original-text-type" sx={{
        backgroundColor: '#E9EEF1',
        textAlign: 'left',
        column: '1',
    }}>
                  {productDecisionsComparisons[0].originalDecision.type}
                </Box>
                <Box data-testid="product-comparison-original-text-values" sx={{
        textAlign: 'center',
        whiteSpace: 'pre-line',
    }}>
                  {showValues(productDecisionsComparisons[0].originalDecision.values)}
                </Box>
              </Flex>
              <div />
              <div />
              <Spacer />
              <Flex sx={{
        padding: '10px',
        borderRadius: '.8rem',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '60px',
        minHeight: '60px',
        backgroundColor: '#E0F7FA',
    }}>
                <Box data-testid="product-comparison-new-text-type" sx={{
        backgroundColor: '#E0F7FA',
        textAlign: 'center',
        column: '1',
        padding: '10px',
    }}>
                  {productDecisionsComparisons[0].newDecision.type}
                </Box>
                <Box data-testid="product-comparison-new-text-values" sx={{
        textAlign: 'center',
        whiteSpace: 'pre-line',
        column: '2',
        padding: '10px',
    }}>
                  {showValues(productDecisionsComparisons[0].newDecision.values)}
                </Box>
              </Flex>
            </Flex>
          </Flex>)}
    </>);
};
export default DecisionComparisons;
