import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MaleImage from '../../../../../../public/static/img/male.png';
import './PolicySummaryHeader.css';
var PolicySummaryHeader = function (_a) {
    var isMale = _a.isMale, name = _a.name, age = _a.age;
    var t = useTranslation(['policy-management/summary']).t;
    return (<Flex sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: 'white',
        justifyContent: 'left',
    }}>
      <Box>
        <Image src={MaleImage} sx={{
        width: '56px',
        height: '56px',
        borderRadius: '50%',
    }}/>
      </Box>
      <Box>
        <Flex sx={{
        flexDirection: 'column',
    }}>
          <Box>
            <div style={{
        fontSize: '32px',
        fontWeight: 'bold',
    }}>
              {name}
            </div>
          </Box>
          <Box> {t('policy-management/summary:POLICY_HEADER.TITLES.MALE')}</Box>
        </Flex>
      </Box>
    </Flex>);
};
export default PolicySummaryHeader;
