var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable react/button-has-type */
import { Alert, AlertIcon, Box, Flex } from '@chakra-ui/react';
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinusCircle, FaPlusCircle, FaSort, FaSortDown, FaSortUp, } from 'react-icons/fa';
import { useExpanded, useGroupBy, usePagination, useRowSelect, useSortBy, useTable, } from 'react-table';
import { IndeterminateCheckbox, Table, Tbody, Td, Th, Thead, Tr, } from '@/components/Table';
import Pagination from '@/components/Pagination/Pagination';
import LoadingIndicator from '@/components/LoadingIndicator/LoadingIndicator';
var useControlledState = function (state, meta) {
    return React.useMemo(function () { return (__assign({}, state)); }, [state]);
};
var DataTable = function (_a) {
    var columns = _a.columns, data = _a.data, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, _c = _a.isError, isError = _c === void 0 ? false : _c, _d = _a.totalPages, totalPages = _d === void 0 ? 0 : _d, _e = _a.disableSortBy, disableSortBy = _e === void 0 ? false : _e, _f = _a.selectedRows, selectedRows = _f === void 0 ? {} : _f, _g = _a.disableRowSelection, disableRowSelection = _g === void 0 ? true : _g, _h = _a.initialGroupBy, initialGroupBy = _h === void 0 ? [] : _h, _j = _a.setSelectedRows, setSelectedRows = _j === void 0 ? function () { return null; } : _j, _k = _a.onSetData, onSetData = _k === void 0 ? function () { return null; } : _k, _l = _a.initialSortBy, initialSortBy = _l === void 0 ? [] : _l, _m = _a.controlledState, controlledState = _m === void 0 ? useControlledState : _m, _o = _a.isManualSortBy, isManualSortBy = _o === void 0 ? true : _o, _p = _a.isDisableSortRemove, isDisableSortRemove = _p === void 0 ? true : _p;
    var _q = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0,
            selectedRowIds: selectedRows,
            groupBy: initialGroupBy,
            sortBy: initialSortBy,
        },
        useControlledState: function (state, meta) { return controlledState(state, meta); },
        manualPagination: true,
        manualSortBy: isManualSortBy,
        autoResetPage: false,
        autoResetSortBy: false,
        pageCount: totalPages,
        disableSortBy: disableSortBy,
        disableMultiSort: true,
        disableSortRemove: isDisableSortRemove,
    }, useGroupBy, useSortBy, useExpanded, usePagination, useRowSelect, function (hooks) {
        if (!disableRowSelection) {
            hooks.visibleColumns.push(function (columns) { return __spreadArrays([
                {
                    id: 'selection',
                    width: '3%',
                    disableSortBy: true,
                    Header: function (_a) {
                        var getToggleAllRowsSelectedProps = _a.getToggleAllRowsSelectedProps;
                        return (<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()}/>);
                    },
                    Cell: function (_a) {
                        var row = _a.row;
                        return (<IndeterminateCheckbox {...row.getToggleRowSelectedProps()}/>);
                    },
                }
            ], columns); });
        }
    }), getTableProps = _q.getTableProps, getTableBodyProps = _q.getTableBodyProps, headerGroups = _q.headerGroups, page = _q.page, prepareRow = _q.prepareRow, pageCount = _q.pageCount, nextPage = _q.nextPage, previousPage = _q.previousPage, _r = _q.state, pageIndex = _r.pageIndex, sortBy = _r.sortBy, selectedRowIds = _r.selectedRowIds;
    var t = useTranslation(['toast']).t;
    React.useEffect(function () {
        onSetData(pageIndex, sortBy);
    }, [onSetData, pageIndex, sortBy]);
    React.useEffect(function () {
        setSelectedRows(selectedRowIds);
    }, [setSelectedRows, selectedRowIds]);
    return (<>
      <Table {...getTableProps()} sx={{
        borderCollapse: 'Separate',
        borderSpacing: '0 15px',
        border: 'none',
    }}>
        <Thead>
          {headerGroups.map(function (headerGroup) { return (<Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(function (column) {
        var _a;
        return (<Th {...column.getHeaderProps(column.getSortByToggleProps())} width={(_a = column.width) !== null && _a !== void 0 ? _a : 'auto'}>
                  <Flex sx={{ alignItems: 'center' }}>
                    {column.render('Header')}
                    {disableSortBy ||
            column.disableSortBy ? null : column.isSorted ? (column.isSortedDesc ? (<FaSortUp />) : (<FaSortDown />)) : (<FaSort />)}
                  </Flex>
                </Th>);
    })}
            </Tr>); })}
        </Thead>
        {isError && (<Tbody>
            <Tr>
              <Td colSpan={columns.length + 1}>
                <Alert status="error" variant="left-accent">
                  <AlertIcon />
                  {t('toast:TOAST.ERROR.RESPONSE')}
                </Alert>
              </Td>
            </Tr>
          </Tbody>)}
        {isLoading && (<Tbody>
            <Tr>
              <Td colSpan={columns.length + 1}>
                <LoadingIndicator />
              </Td>
            </Tr>
          </Tbody>)}
        {!isLoading && !isError && (<Tbody {...getTableBodyProps()}>
            {!page.length ? (<Tr>
                <Td colSpan={columns.length + 1}>
                  <Alert status="info" variant="left-accent">
                    <AlertIcon />
                    {t('toast:TOAST.WARNING.NO_DATA')}
                  </Alert>
                </Td>
              </Tr>) : (page.map(function (row) {
        prepareRow(row);
        var rowProps = row.getRowProps();
        return (<React.Fragment key={rowProps.key}>
                    <Tr {...row.getRowProps()}>
                      {row.cells.map(function (cell) {
            return (<Td {...cell.getCellProps()} {...(initialGroupBy.length && __assign({}, row.getToggleRowExpandedProps()))} style={{
                cursor: cell.isGrouped || cell.isAggregated
                    ? 'pointer'
                    : 'default',
            }}>
                            {cell.isGrouped ? (<Flex sx={{
                alignItems: 'center',
                color: 'white',
                fontWeight: 'bold',
                textTransform: 'uppercase',
            }}>
                                {row.isExpanded ? (<Box as={FaMinusCircle} sx={{ mr: '.3em', color: 'white' }}/>) : (<Box as={FaPlusCircle} sx={{ mr: '.3em', color: 'white' }}/>)}
                                {cell.render('Cell')}
                              </Flex>) : cell.isAggregated ? (cell.render('Aggregated')) : cell.isPlaceholder ? (cell.render('SubCell')) : (cell.render('Cell'))}
                          </Td>);
        })}
                    </Tr>
                  </React.Fragment>);
    }))}
          </Tbody>)}
      </Table>
      {pageCount > 1 ? (<Pagination pageIndex={pageIndex} pageCount={pageCount} nextPage={nextPage} previousPage={previousPage}/>) : null}
    </>);
};
export default DataTable;
