import React from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { useTranslation, Trans } from 'react-i18next';
var Pagination = function (_a) {
    var pageIndex = _a.pageIndex, pageCount = _a.pageCount, nextPage = _a.nextPage, previousPage = _a.previousPage;
    var t = useTranslation(['pagination']).t;
    return (<Flex data-testid="pagination" sx={{
        float: 'right',
        alignItems: 'center',
    }}>
      <IconButton icon={<FaChevronCircleLeft />} isDisabled={pageIndex === 0} aria-label={t('pagination:PREVIOUS')} variant="ghost" sx={{
        fontSize: '1.5rem',
    }} onClick={previousPage}/>
      <Box>
        <Text>
          <Trans i18nKey="pagination:PAGE_OF" values={{ pageIndex: pageIndex + 1, pageCount: pageCount }}>
            Page {{ pageIndex: pageIndex }} of {{ pageCount: pageCount }}
          </Trans>
        </Text>
      </Box>
      <IconButton icon={<FaChevronCircleRight />} isDisabled={pageIndex === pageCount - 1} aria-label={t('pagination:NEXT')} variant="ghost" sx={{
        fontSize: '1.5rem',
    }} onClick={nextPage}/>
    </Flex>);
};
export default Pagination;
