var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Badge, Box, Button, Center, Flex, Heading, Link, Select, Spacer, Text, } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMetrics } from '@/services/segment/metrics.provider';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import { RunStatus } from '@/domain/Run/Run';
import { DataTable } from '@/components/Table';
import SearchBox from '@/components/Document/SearchBox/SearchBox';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import useListPoliciesAndRun from '@/domain/Misrep/hooks/useListPoliciesAndStatus/useListPoliciesAndStatus';
import './PolicyManagementPage.css';
import { updatePolicy } from '@/domain/Policy/PolicyService';
import IconPopover from '@/components/PopOver/IconPopover';
function determinePolicyStatus(runId, status) {
    var policyStatus;
    if (runId === undefined) {
        policyStatus = RunStatus.IN_PROGRESS;
    }
    else {
        policyStatus = status;
    }
    return policyStatus;
}
export var Action;
(function (Action) {
    Action["VIEW_POLICY"] = "View Policy";
    Action["PLEASE_WAIT"] = "Please Wait...";
    Action["DEFAULT"] = "Pending";
})(Action || (Action = {}));
function determineAction(runId, status) {
    if (status === RunStatus.MISREP) {
        return Action.VIEW_POLICY;
    }
    if (status === RunStatus.PASSED) {
        return Action.VIEW_POLICY;
    }
    if (status === RunStatus.IN_PROGRESS) {
        return Action.PLEASE_WAIT;
    }
    return Action.DEFAULT;
}
function getStatusColor(value) {
    switch (value) {
        case RunStatus.MISREP:
            return 'brand.red';
        case RunStatus.PASSED:
            return 'brand.green';
        case RunStatus.ERROR:
            return 'brand.orange';
        case RunStatus.IN_PROGRESS:
            return 'brand.blue';
        default:
            return 'brand.blue';
    }
}
function getActionStatusColor(value) {
    switch (value) {
        case 'Drop Evidence':
            return 'orange';
        case 'View Policy':
            return 'brand.blue';
        case 'Please Wait...':
            return 'gray';
        case 'Pending':
            return 'brand.orange';
        default:
            return 'gray';
    }
}
export var SortBy;
(function (SortBy) {
    SortBy["CREATION_DATE"] = "creationDate";
    SortBy["POLICY_REFERENCE"] = "policyReference";
    SortBy["CREATED_DATE"] = "createdDate";
})(SortBy || (SortBy = {}));
var DataAccessors;
(function (DataAccessors) {
    DataAccessors["STATUS"] = "status";
    DataAccessors["ACTIONS"] = "actions";
    DataAccessors["DETAILS"] = "details";
    DataAccessors["APPLICATION_DATE"] = "applicationDate";
    DataAccessors["POLICY_REFERENCE"] = "policyReference";
    DataAccessors["CREATED_DATE"] = "createdDate";
    DataAccessors["EXPECTED_POLICY_OUYTCOME"] = "expectedPolicyOutcome";
})(DataAccessors || (DataAccessors = {}));
var AllowedSorts = {
    runDateDescending: {
        name: 'Run Date, Desc',
        policyRunSortBy: [{ id: SortBy.CREATED_DATE, desc: true }],
    },
    runDateAscending: {
        name: 'Run Date, Asc',
        policyRunSortBy: [{ id: SortBy.CREATED_DATE, desc: false }],
    },
};
var PolicyManagementPage = function () {
    var token = useAuthToken().token;
    var tenant = useTenantContext().tenant;
    var t = useTranslation(['policy-management/view']).t;
    var tCommon = useTranslation(['common']).t;
    var initialSortBy = [
        {
            id: SortBy.CREATED_DATE,
            desc: true,
        },
    ];
    var _a = React.useState(-1), page = _a[0], setPage = _a[1];
    var _b = React.useState(initialSortBy), sortBy = _b[0], setSortBy = _b[1];
    var _c = React.useState(''), searchText = _c[0], setSearchText = _c[1];
    var _d = useListPoliciesAndRun({ page: page, sortBy: sortBy, tenant: tenant, token: token, searchText: searchText }), policyRuns = _d.data, isRunsLoading = _d.isLoading, isRunsError = _d.isError, refetchRuns = _d.refetch;
    var metrics = useMetrics();
    useEffect(function () {
        metrics.analytics.page('Policy List Home', {
            title: 'Policy List Page',
            url: window.location.hostname,
        });
    }, []);
    React.useEffect(function () {
        if (page > -1 || sortBy.length) {
            refetchRuns();
        }
    }, [page, sortBy, refetchRuns, searchText]);
    var onSearch = function (st) {
        setSearchText(st);
    };
    var handleChange = function (e) {
        switch (e.target.value) {
            case 'runDateAscending': {
                setSortBy(AllowedSorts.runDateAscending.policyRunSortBy);
                refetchRuns();
                break;
            }
            case 'runDateDescending':
            default: {
                setSortBy(AllowedSorts.runDateDescending.policyRunSortBy);
                refetchRuns();
                break;
            }
        }
    };
    var handleUpdateExpectedOutcome = function (policy, expectedOutcome) { return __awaiter(void 0, void 0, void 0, function () {
        var policyReference, age, sex, product, applicationDate, createPolicy;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyReference = policy.policyReference, age = policy.age, sex = policy.sex, product = policy.product, applicationDate = policy.applicationDate;
                    createPolicy = {
                        age: age,
                        sex: sex,
                        product: product,
                        applicationDate: applicationDate,
                        expectedPolicyOutcome: expectedOutcome,
                    };
                    return [4 /*yield*/, updatePolicy(policyReference, createPolicy, tenant, token)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refetchRuns()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var data = React.useMemo(function () { var _a; return (_a = policyRuns === null || policyRuns === void 0 ? void 0 : policyRuns.content) !== null && _a !== void 0 ? _a : []; }, [policyRuns]);
    var columns = React.useMemo(function () { return [
        {
            Header: function () {
                return t('policy-management/view:TABLE.HDR.POLICY_REFERENCE');
            },
            accessor: DataAccessors.POLICY_REFERENCE,
            width: '15%',
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value, row = _a.row;
                var runId = row.original.runId, _b = row.values, policyReference = _b.policyReference, status = _b.status;
                return status === RunStatus.MISREP || status === RunStatus.PASSED ? (<Link data-testid="policyReferenceLink" href={"/policy-management/view/" + policyReference + "/summary?runId=" + runId}>
              {value}
            </Link>) : (value);
            },
        },
        {
            Header: function () {
                return t('policy-management/view:TABLE.HDR.DETAILS');
            },
            accessor: DataAccessors.DETAILS,
            width: '15%',
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value, row = _a.row;
                return <Text>{value}</Text>;
            },
        },
        {
            Header: function () {
                return t('policy-management/view:TABLE.HDR.APPLICATION_DATE');
            },
            accessor: DataAccessors.APPLICATION_DATE,
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value;
                return (<Text data-testid="application-date" suppressHydrationWarning>
              {new Date(value).toLocaleDateString()}
            </Text>);
            },
            width: '15%',
        },
        {
            Header: function () {
                return t('policy-management/view:TABLE.HDR.RUN_DATE');
            },
            accessor: DataAccessors.CREATED_DATE,
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value;
                return (<Text data-testid="policy-created-date" suppressHydrationWarning>
              {new Date(value).toLocaleDateString()}
            </Text>);
            },
            width: '12%',
        },
        {
            Header: function () {
                return <Box sx={{ margin: 'auto' }}>Expected Outcome</Box>;
            },
            accessor: DataAccessors.EXPECTED_POLICY_OUYTCOME,
            width: '10%',
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value, row = _a.row;
                return (<Box textAlign="center">
              <IconPopover initialValue={value} handleChange={function (value) {
                    return handleUpdateExpectedOutcome(row.original, value);
                }}/>
            </Box>);
            },
        },
        {
            Header: function () {
                return (<Box sx={{ margin: 'auto' }}>
              {t('policy-management/view:TABLE.HDR.STATUS')}
            </Box>);
            },
            accessor: DataAccessors.STATUS,
            width: '15%',
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value, row = _a.row;
                var runId = row.original.runId, status = row.values.status;
                var policyStatus = determinePolicyStatus(runId, status);
                return (<Flex sx={{ justifyContent: 'center' }}>
              <Badge as="div" variant="solid" colorScheme={getStatusColor(policyStatus)} sx={{
                    padding: '.2rem .5rem',
                    borderRadius: '.5rem',
                    minWidth: '36%',
                    textAlign: 'center',
                }}>
                {policyStatus}
              </Badge>
            </Flex>);
            },
        },
        {
            Header: function () {
                return (<Box sx={{ margin: 'auto' }}>
              {t('policy-management/view:TABLE.HDR.ACTIONS')}
            </Box>);
            },
            accessor: DataAccessors.ACTIONS,
            width: '10%',
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value, row = _a.row;
                var runId = row.original.runId, _b = row.values, status = _b.status, policyReference = _b.policyReference;
                var actionStatus = determineAction(runId, status);
                return actionStatus === 'View Policy' ? (<Flex sx={{ justifyContent: 'center' }}>
              <Badge as="a" href={"/policy-management/view/" + policyReference + "/summary?runId=" + runId} variant="solid" colorScheme={getActionStatusColor(actionStatus)} sx={{
                    padding: '.2rem .5rem',
                    borderRadius: '.5rem',
                    minWidth: '24%',
                    textAlign: 'center',
                }}>
                {actionStatus}
              </Badge>
            </Flex>) : (<Flex sx={{ justifyContent: 'center' }}>
              <Badge as="div" variant="solid" colorScheme={getActionStatusColor(actionStatus)} sx={{
                    padding: '.2rem .5rem',
                    borderRadius: '.5rem',
                    minWidth: '36%',
                    textAlign: 'center',
                }}>
                {actionStatus}
              </Badge>
            </Flex>);
            },
        },
    ]; }, [t]);
    return (<>
      <Flex sx={{
        flexDirection: 'column',
    }}>
        <Flex sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: 'white',
        justifyContent: 'center',
        paddingLeft: '100px',
        paddingRight: '100px',
    }}>
          <Heading as="h1" variant="page-header">
            <Center> {t('common:VIEW_POLICY_RUNS')} </Center>
          </Heading>
          <Spacer />
          <Box>
            <Button colorScheme="cta.primary" as="a" href="/policy-management/create">
              {tCommon('common:CREATE_POLICY')}
            </Button>
          </Box>
        </Flex>

        <Flex sx={{
        flexDirection: 'row',
        backgroundColor: 'brand.grey.100',
        paddingTop: '15px',
        paddingBottom: '5px',
        paddingLeft: '100px',
        paddingRight: '100px',
    }}>
          <Box sx={{
        paddingLeft: '10px',
        minW: '500px',
    }}>
            <SearchBox onSearch={onSearch} placeholder={tCommon('common:SEARCH_PLACEHOLDER')}/>
          </Box>
          <Spacer />
          <Box sx={{
        paddingLeft: '10px',
    }}>
            <div style={{ float: 'left', paddingTop: '8px', paddingRight: '5px' }}>
              {tCommon('common:FILTER')}
            </div>
            <div style={{ float: 'right' }}>
              <Select sx={{}} placeholder="View All"/>
            </div>
          </Box>
          <Box sx={{
        paddingLeft: '10px',
    }}>
            <div style={{ float: 'left', paddingTop: '8px', paddingRight: '5px' }}>
              {tCommon('common:SORT_BY')}
            </div>
            <div style={{ float: 'right' }}>
              <Select name="item-selected" onChange={handleChange}>
                {Object.keys(AllowedSorts).map(function (key) { return (<option key={key} value={key}>
                    {AllowedSorts[key].name}
                  </option>); })}
              </Select>
            </div>
          </Box>
        </Flex>

        <Flex sx={{
        paddingLeft: '100px',
        paddingRight: '100px',
        flexDirection: 'column',
    }}>
          <DataTable columns={columns} data={data} isLoading={isRunsLoading} isError={isRunsError} totalPages={policyRuns === null || policyRuns === void 0 ? void 0 : policyRuns.totalPages} initialSortBy={initialSortBy} onSetData={function (page, sortBy) {
        setPage(page);
        setSortBy(sortBy);
    }}/>
        </Flex>
      </Flex>
    </>);
};
export default PolicyManagementPage;
