/* eslint-disable react/jsx-props-no-spreading */
import { Center, FormControl, FormErrorMessage, FormLabel, Heading, Icon, Input, InputGroup, InputRightElement, VStack, } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';
import enquirySchema from '@/components/Form/Enquiry/enquiry.schema';
import { BrandSpinnerIcon } from '@/components/LoadingIndicator/LoadingIndicator';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import useGetEnquiryById from '@/domain/Policy/hooks/useGetEnquiryById/useGetEnquiryById';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import useDebounce from '@/hooks/useDebounce/useDebounce';
var EnquiryForm = function (_a) {
    var formValues = _a.formValues, enquiryId = _a.enquiryId, policyReference = _a.policyReference, setEnquiryId = _a.setEnquiryId, formikRef = _a.formikRef, setIsValidEnquiry = _a.setIsValidEnquiry;
    var t = useTranslation(['policy-management/enquiry']).t;
    var tenant = useTenantContext().tenant;
    var token = useAuthToken().token;
    var UUID_NUMBER_LENGTH = 36;
    var _b = useGetEnquiryById(enquiryId, policyReference, tenant, token), data = _b.data, error = _b.error, isLoading = _b.isLoading, refetch = _b.refetch;
    var isInvalid = function (error) {
        return (error === null || error === void 0 ? void 0 : error.response.status) === 404 || (error === null || error === void 0 ? void 0 : error.response.status) === 400;
    };
    var isSystemError = function (error) {
        return (error === null || error === void 0 ? void 0 : error.response.status) === 500;
    };
    var isValid = function (error) {
        return ((error === null || error === void 0 ? void 0 : error.response.status) !== 404 &&
            (error === null || error === void 0 ? void 0 : error.response.status) !== 400 &&
            (error === null || error === void 0 ? void 0 : error.response.status) !== 500);
    };
    var debouncedEnquiryId = useDebounce(enquiryId);
    var determineEnquiryIdIcon = function (form) {
        if (isLoading) {
            return <BrandSpinnerIcon size="sm"/>;
        }
        if (enquiryId.length === UUID_NUMBER_LENGTH && isValid(error)) {
            return (<Icon as={FaCheck} color="brand.status.success" data-testid="enquiry-id-valid"/>);
        }
        if (form.errors.enquiryId || isInvalid(error)) {
            return (<Icon as={FaTimes} color="brand.status.error" data-testid="enquiry-not-found"/>);
        }
        return null;
    };
    React.useEffect(function () {
        if (debouncedEnquiryId.length === UUID_NUMBER_LENGTH) {
            refetch();
        }
    }, [debouncedEnquiryId, refetch]);
    return (<>
      <Heading as="h1" variant="page-header">
        <Center> {t('common:ENQUIRY_DETAILS')} </Center>
      </Heading>
      <Center sx={{ height: '50px' }}>
        {t('policy-management/enquiry:FORM.HEADER.INFO')}
      </Center>
      <Formik innerRef={formikRef} initialValues={formValues} validationSchema={enquirySchema} onSubmit={function () { }}>
        {function (form) { return (<Form id="enquiry-details" data-testid="form" noValidate>
            <VStack align="center" spacing={5}>
              <Center sx={{ w: '40%' }}>
                <Field name="enquiryId" validate={function (value) {
        setEnquiryId(value);
        if (form.errors.enquiryId === undefined &&
            enquiryId.length === 0) {
            return null;
        }
        if (form.errors.enquiryId ===
            'policy-management/enquiry:FORM.ERROR.ENQUIRY_ID.FORMAT') {
            setIsValidEnquiry(false);
            return t('policy-management/enquiry:FORM.ERROR.ENQUIRY_ID.FORMAT');
        }
        if (form.errors.enquiryId === undefined && isValid(error)) {
            setIsValidEnquiry(true);
        }
        if (isInvalid(error)) {
            setIsValidEnquiry(false);
            return t('policy-management/enquiry:FORM.ERROR.ENQUIRY_ID.INVALID');
        }
        if (isSystemError(error)) {
            setIsValidEnquiry(false);
            return t('policy-management/enquiry:FORM.ERROR.ENQUIRY_ID.SYSTEM_ERROR');
        }
        return null;
    }}>
                  {function (_a) {
        var field = _a.field, form = _a.form;
        return (<FormControl id="enquiryId" isInvalid={form.errors.enquiryId && form.touched.enquiryId} isRequired={false} sx={{
            mb: 1,
            align: 'center',
        }}>
                      <FormLabel htmlFor="enquiryId">
                        {t('policy-management/enquiry:FORM.LABEL.ENQUIRY_ID')}
                      </FormLabel>
                      <InputGroup>
                        <Input data-testid="enquiryId" {...field} backgroundColor="white"/>
                        <InputRightElement sx={{
            paddingRight: 0,
        }}>
                          {determineEnquiryIdIcon(form)}
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {t(form.errors.enquiryId)}
                      </FormErrorMessage>
                    </FormControl>);
    }}
                </Field>
              </Center>
            </VStack>
          </Form>); }}
      </Formik>
    </>);
};
export default EnquiryForm;
