import React from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import UploadState from '../UploadState/UploadState';
var UploadList = function (_a) {
    var documentList = _a.documentList;
    var t = useTranslation(['common']).t;
    var _b = React.useState({}), uploadList = _b[0], setUploadList = _b[1];
    React.useEffect(function () {
        if (documentList.length) {
            var groupedList = groupBy(documentList, 'policyReference');
            setUploadList(groupedList);
        }
        else {
            setUploadList({});
        }
    }, [documentList]);
    return (<Flex sx={{ flexDirection: 'column', flex: 1 }}>
      {Object.keys(uploadList).map(function (policyReference) { return (<Box key={policyReference}>
          <Container>
            {uploadList[policyReference].map(function (_a) {
        var filename = _a.filename, status = _a.status, progress = _a.progress;
        return (<UploadState key={"" + policyReference + filename} status={status} progress={progress} filename={filename}/>);
    })}
          </Container>
        </Box>); })}
    </Flex>);
};
export default UploadList;
