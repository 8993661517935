/* eslint-disable import/prefer-default-export */
export function makeSortParamValue(sortBy) {
    return sortBy
        .map(function (_a) {
        var id = _a.id, desc = _a.desc;
        return id + "," + (desc ? 'desc' : 'asc');
    })
        .join(',');
}
export function makeQueryParams(params) {
    var result = {};
    Object.keys(params).forEach(function (key) {
        var _a;
        if (params[key]) {
            Object.assign(result, (_a = {}, _a[key] = params[key], _a));
        }
    });
    return new URLSearchParams(result).toString();
}
