import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import nextId from 'react-id-generator';
import MisRepItem from '../MisRepItem/MisRepItem';
var MisRepSection = function (_a) {
    var title = _a.title, description = _a.description, misrepResults = _a.misrepResults, misrepLabel = _a.misrepLabel, misrepLabelColour = _a.misrepLabelColour, policyReference = _a.policyReference, runId = _a.runId, dataTestId = _a.dataTestId;
    return (<>
      <Box mt="4" sx={{
        flexDirection: 'row',
    }}>
        <Text as="b" fontSize="2xl" data-testid={dataTestId}>
          {title}
        </Text>
        <Badge borderRadius="full" px="2" color="white" mb="7" ml="1" backgroundColor={misrepLabelColour + ".900"}>
          {misrepResults.length}
        </Badge>
        <Text>{description}</Text>
      </Box>
      <Box>
        <Flex sx={{
        flexDirection: 'column',
    }}>
          {misrepResults.map(function (misRepResult) { return (<Box key={nextId()}>
              <MisRepItem policyReference={policyReference} runId={runId} misrepResult={misRepResult} misrepLabel={misrepLabel} misrepLabelColour={misrepLabelColour}/>
            </Box>); })}
        </Flex>
      </Box>
    </>);
};
export default MisRepSection;
