var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { POLICY_SERVICE_API } from '@/constants/app';
import axios from '@/libs/axiosConfig/axiosConfig';
export function createPolicy(policyFormData, tenant, token) {
    return axios.post(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies", policyFormData, {
        headers: { Authorization: "Bearer " + token },
    });
}
export function updatePolicy(policyReference, policyFormData, tenant, token) {
    return axios.put(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies/" + policyReference, policyFormData, {
        headers: { Authorization: "Bearer " + token },
    });
}
export function getPolicyByReference(_a) {
    var policyReference = _a.policyReference, _b = _a.tenant, tenant = _b === void 0 ? null : _b, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies/" + policyReference, {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function doPolicyDocumentUpload(policyReference, file, tenant, token, callback) {
    var formData = new FormData();
    formData.append('file', file, file.name);
    return axios
        .post(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies/" + policyReference + "/documents", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
            var loaded = progressEvent.loaded, total = progressEvent.total;
            var percentage = Math.round((loaded * 100) / total);
            callback(percentage, file, policyReference, false, tenant);
        },
    })
        .catch(function (e) {
        callback(100, file, policyReference, true, tenant);
    });
}
export function uploadPolicyAppForm(policyReference, appForm, tenant, token) {
    return axios.post(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies/" + policyReference + "/appForm", appForm, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
    });
}
export function doPolicyDocumentDownload(policyReference, txtFilename, tenant, token) {
    return __awaiter(this, void 0, Promise, function () {
        var data, error_1, text;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies/" + policyReference + "/documents/" + txtFilename, {
                            responseType: 'blob',
                            headers: { Authorization: "Bearer " + token },
                        })];
                case 1:
                    (data = (_a.sent()).data);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    if (error_1.response) {
                        // Request made and server responded
                        console.log(error_1.response.data);
                        console.log(error_1.response.status);
                        console.log(JSON.stringify(error_1.response.headers));
                        console.log(JSON.stringify(error_1.response.config));
                    }
                    else if (error_1.request) {
                        // The request was made but no response was received
                        console.log(error_1.request);
                    }
                    else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('error in doPolicyDocumentDownload');
                        console.log('Error', error_1.message);
                    }
                    return [3 /*break*/, 3];
                case 3: return [4 /*yield*/, new Response(data).text()];
                case 4:
                    text = _a.sent();
                    return [2 /*return*/, text !== undefined && text.length >= 1
                            ? text
                            : 'No .txt file available for the chosen policy'];
            }
        });
    });
}
export function doModelRunForAPolicy(policyReference, tenant, token) {
    return axios.post(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies/" + policyReference + "/scheduledItems", '', {
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
    });
}
export function startEnquiryProcess(tenant, token, policyReference, enquiryId) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, axios.post(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/policies/" + policyReference + "/enquiryId/" + enquiryId, '', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                })];
        });
    });
}
export function listPolicies(_a) {
    var _b = _a.params, params = _b === void 0 ? '' : _b, _c = _a.tenant, tenant = _c === void 0 ? null : _c, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies?" + params, {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_d.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getStaticAppFormQuestions(_a) {
    var tenant = _a.tenant, token = _a.token, product = _a.product, age = _a.age, gender = _a.gender;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/staticAppForm?product=" + product + "&age=" + age + "&gender=" + gender, {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
