var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Popover } from 'react-tiny-popover';
import './Annotation.css';
import right from './images/chevron-right-icon.png';
import left from './images/left-chevron-icon.png';
var Annotation = /** @class */ (function (_super) {
    __extends(Annotation, _super);
    function Annotation(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { isPopOverOpen: false };
        return _this;
    }
    Annotation.prototype.render = function () {
        var _this = this;
        var isPopOverOpen = this.state.isPopOverOpen;
        var annotation = this.props.AnnotationItem;
        var categoryClassName = annotation.isHighlightedText
            ? 'Highlighted'
            : annotation.category;
        return (<Popover isOpen={isPopOverOpen} positions={['right']} padding={10} reposition={false} onClickOutside={function () { return _this.setState({ isPopOverOpen: false }); }} content={function (_a) {
            var _b, _c, _d, _e, _f, _g;
            var position = _a.position, nudgedLeft = _a.nudgedLeft, nudgedTop = _a.nudgedTop;
            return (<div className="Annotation-Popup">
            <div>
              <b>Start:</b> {annotation.begin}
              <b> End:</b> {annotation.end}
              <b> ID:</b> {annotation.riskId}
            </div>
            {annotation.externalReference !== null && (<div>
                <div>
                  <b>SNOMED ID:</b> {(_b = annotation.externalReference) === null || _b === void 0 ? void 0 : _b.id}
                </div>
                <div>
                  <b>SNOMED Name:</b> {(_c = annotation.externalReference) === null || _c === void 0 ? void 0 : _c.text}
                </div>
              </div>)}
            {annotation.chosenNormalisation !== null && (<div>
                <div>
                  <b>Chosen Normalisation: </b>{' '}
                  {(_d = annotation.chosenNormalisation) === null || _d === void 0 ? void 0 : _d.normalisationId}{' '}
                  <b>Source:</b> {(_e = annotation.chosenNormalisation) === null || _e === void 0 ? void 0 : _e.source}
                </div>
                <div>
                  <b>Normalisation Name:</b>{' '}
                  {(_f = annotation.chosenNormalisation) === null || _f === void 0 ? void 0 : _f.normalisationName}{' '}
                  <b>Source:</b> {(_g = annotation.chosenNormalisation) === null || _g === void 0 ? void 0 : _g.source}
                </div>
              </div>)}
            <div>
              <b>Positive Score:</b> {annotation.positiveScore}{' '}
              <b>Risk Score:</b> {annotation.riskScore}
            </div>
          </div>);
        }}>
        
        <div className="annotation-container-main" data-testid="annotation">
          <div className="annotation-container-top">
            <div className="annotation-chevron-left" data-testid="annotation-chevron">
              <img alt="left" src={left} className="annotation-chevron-image"/>
            </div>
            <span className={categoryClassName} style={{ fontSize: 8 }} data-testid="annotation-concept-type">
              {annotation.isHighlightedText}
              {annotation.category}
            </span>
            <div className="annotation-chevron-right" data-testid="annotation-chevron">
              <img alt="right" src={right} className="annotation-chevron-image"/>
            </div>
          </div>
          <div>
            <span className={categoryClassName} data-testid="annotation-text">
              {annotation.unnormalisedName}
            </span>
            <span style={{ marginRight: '8px' }}/>
          </div>
        </div>
      </Popover>);
    };
    return Annotation;
}(React.Component));
export default Annotation;
