import React, { useState, useEffect } from 'react';
var TENANT_STATE_KEY = 'tenant';
var TENANTS_STATE_KEY = 'tenants';
var TenantContext = React.createContext({
    tenant: '',
    setTenant: function () { },
    tenants: [],
    setTenants: function () { },
});
TenantContext.displayName = 'TenantContext';
function TenantContextProvider(_a) {
    var children = _a.children;
    var _b = useState(''), tenant = _b[0], setTenant = _b[1];
    var _c = useState([]), tenants = _c[0], setTenants = _c[1];
    useEffect(function () {
        var sessionTenantString = sessionStorage.getItem(TENANT_STATE_KEY);
        if (!sessionTenantString) {
            setTenant('');
            return;
        }
        var tenant = JSON.parse(sessionTenantString);
        if (tenant) {
            setTenant(tenant);
        }
    }, []);
    useEffect(function () {
        if (!tenant)
            return;
        sessionStorage.setItem(TENANT_STATE_KEY, JSON.stringify(tenant));
    }, [tenant]);
    useEffect(function () {
        var sessionTenantsString = sessionStorage.getItem(TENANTS_STATE_KEY);
        if (!sessionTenantsString) {
            setTenants([]);
            return;
        }
        var tenants = JSON.parse(sessionTenantsString);
        if (tenants) {
            setTenants(tenants);
        }
    }, []);
    useEffect(function () {
        if (!tenants)
            return;
        sessionStorage.setItem(TENANTS_STATE_KEY, JSON.stringify(tenants));
        if (tenant.length === 0 && tenants.length > 0) {
            setTenant(tenants[0]);
        }
    }, [tenants]);
    return (<TenantContext.Provider value={{ tenant: tenant, setTenant: setTenant, tenants: tenants, setTenants: setTenants }}>
      {children}
    </TenantContext.Provider>);
}
export { TenantContextProvider, TenantContext };
export default TenantContext;
