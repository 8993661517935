var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import Dropdown from '@/components/Dropdown/Dropdown';
import { getNonPagedRunFileAnnotations } from '@/domain/Annotation/Annotation';
import { doPolicyDocumentDownload } from '@/domain/Policy/PolicyService';
import { getNonPagedRunFilenames } from '@/domain/RiskFilename/RiskFilename';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import AnnotationViewer from '@/pages/policy-management/view/[policyReference]/AnnotationViewer/AnnotationViewer';
var FullReport = function (_a) {
    var policyReference = _a.policyReference, runId = _a.runId, initialSelectedRiskId = _a.initialSelectedRiskId, initialFilename = _a.initialFilename, token = _a.token, nerSpanBegin = _a.nerSpanBegin, nerSpanEnd = _a.nerSpanEnd;
    var _b = React.useState({
        annotations: [],
        originalText: '',
    }), annotationData = _b[0], setAnnotationData = _b[1];
    var _c = React.useState([]), filenames = _c[0], setFilenames = _c[1];
    var _d = React.useState(''), filename = _d[0], setFilename = _d[1];
    var tenant = useTenantContext().tenant;
    var handleSelect = function (newFilename) {
        setFilename(newFilename);
    };
    React.useEffect(function () {
        function loadFiles() {
            return __awaiter(this, void 0, Promise, function () {
                var runFilenames;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getNonPagedRunFilenames({
                                runId: runId,
                                tenant: tenant,
                                token: token,
                            })];
                        case 1:
                            runFilenames = (_a.sent()).content;
                            setFilenames(runFilenames.map(function (runFilename) { return runFilename.filename; }));
                            if (runFilenames.length > 0) {
                                handleSelect(initialFilename !== null && initialFilename !== void 0 ? initialFilename : runFilenames[0].filename);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        loadFiles();
    }, [runId, token, tenant]);
    React.useEffect(function () {
        function loadData() {
            return __awaiter(this, void 0, Promise, function () {
                var runFileAnnotations, txtFilename, runFileContents;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!filename) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, getNonPagedRunFileAnnotations({
                                    runId: runId,
                                    filename: filename,
                                    tenant: tenant,
                                    token: token,
                                })];
                        case 1:
                            runFileAnnotations = (_a.sent()).content;
                            txtFilename = filename.replace('.json', '.txt');
                            return [4 /*yield*/, doPolicyDocumentDownload(policyReference, txtFilename, tenant, token)];
                        case 2:
                            runFileContents = _a.sent();
                            console.log('load data complete');
                            setAnnotationData({
                                annotations: runFileAnnotations,
                                originalText: runFileContents,
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        loadData();
    }, [filename, runId, token, tenant]);
    var findRiskId = function (annotations) {
        if (initialSelectedRiskId === undefined &&
            nerSpanBegin !== null &&
            nerSpanEnd !== null) {
            var annotationItem = annotations.find(function (item) {
                return item.begin.toString() === nerSpanBegin &&
                    item.end.toString() === nerSpanEnd;
            });
            if (annotationItem != null) {
                return annotationItem.riskId.toString();
            }
        }
        return initialSelectedRiskId;
    };
    return (<Flex flexDirection="column">
      <Box>
        <div>
          <div>
            <Dropdown initialSelection={initialFilename} handleSelect={handleSelect} values={filenames}/>
          </div>
          <div>
            <AnnotationViewer originalText={annotationData.originalText} annotations={annotationData.annotations} initialSelectedRiskId={filename === initialFilename || initialFilename === undefined
        ? findRiskId(annotationData.annotations)
        : undefined}/>
          </div>
        </div>
      </Box>
    </Flex>);
};
export default FullReport;
