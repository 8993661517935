var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as _ from 'lodash';
import React from 'react';
import TreeMenu from 'react-simple-tree-menu';
import Annotation from '../Annotation/Annotation';
import './AnnotationViewer.css';
var NodeType;
(function (NodeType) {
    NodeType["parent"] = "parent";
    NodeType["child"] = "child";
})(NodeType || (NodeType = {}));
var AnnotationViewer = /** @class */ (function (_super) {
    __extends(AnnotationViewer, _super);
    function AnnotationViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.componentDidUpdate = function () {
            var initialSelectedRiskId = _this.props.initialSelectedRiskId;
            var _a = _this.state, selectedRiskId = _a.selectedRiskId, hasDoneInitialScroll = _a.hasDoneInitialScroll, elementId = _a.elementId;
            if (!selectedRiskId)
                return;
            _this.scrollToAnnotationRiskId(selectedRiskId, elementId);
            if (!hasDoneInitialScroll && selectedRiskId === initialSelectedRiskId) {
                _this.scrollToActiveTreeViewNode(elementId);
            }
        };
        _this.onChangeValue = function (event) {
            _this.setState({
                groupBy: event.target.value,
                treeData: AnnotationViewer.rebuildSidebar(event.target.value, _this.props),
            });
        };
        _this.setActiveNode = function (nodePath, nodeType, isOpen, id) {
            var isGroupNode = nodeType === NodeType.parent;
            var openNodes = _this.state.openNodes;
            var _a = _this.state, selectedRiskId = _a.selectedRiskId, activeNodePath = _a.activeNodePath;
            if (isGroupNode && !isOpen) {
                openNodes.push(nodePath);
            }
            else if (isGroupNode && isOpen) {
                var groupKey_1 = _.split(nodePath, '\\')[0];
                _.remove(openNodes, function (x) { return _.isEqual(x, groupKey_1); });
            }
            else {
                selectedRiskId = id;
                activeNodePath = nodePath;
            }
            _this.setState({
                openNodes: openNodes,
                activeNodePath: activeNodePath,
                selectedRiskId: selectedRiskId,
                hasDoneInitialScroll: true,
            });
        };
        _this.scrollToAnnotationRiskId = function (annotationRiskId, elementId) {
            var element = document.getElementById(annotationRiskId + "-" + elementId + "-div");
            element === null || element === void 0 ? void 0 : element.scrollIntoView({ behavior: 'auto', block: 'start' });
        };
        _this.scrollToActiveTreeViewNode = function (elementId) {
            var parentElement = document.getElementById("left-pane-" + elementId);
            if (parentElement === null)
                return;
            var elementCollection = parentElement.getElementsByClassName("rstm-tree-item--active");
            var elementsArray = Array.prototype.slice.call(elementCollection);
            var element = _.first(elementsArray);
            element === null || element === void 0 ? void 0 : element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        };
        _this.writePlainTextElementsForLineToArray = function (line, keyPretext, k, listOfObjects) {
            if (line.trim() !== '') {
                listOfObjects.push(<div>{line}</div>);
            }
            listOfObjects.push(<div key={keyPretext + "-" + k.toString() + "-break"} className="annotation-viewer-break" data-testid="line-break"/>);
        };
        _this.writePlainText = function (textToRender, listOfObjects) {
            var lines = textToRender.split('\n');
            for (var k = 0; k < lines.length; k += 1) {
                var line = lines[k];
                _this.writePlainTextElementsForLineToArray(line, 'leftover', k, listOfObjects);
            }
        };
        _this.renderAnnotations = function (annotations, originalText, elementId) {
            var listOfObjects = [];
            var initialSelectedRiskId = _this.props.initialSelectedRiskId;
            var sortedAnnotations = _.orderBy(annotations, 'begin', 'asc');
            var startPosition = 0;
            for (var i = 0; i < sortedAnnotations.length; i += 1) {
                var annotation = sortedAnnotations[i];
                var leftSideOfAnnotationString = originalText.substr(startPosition, annotation.begin - startPosition);
                var lines = leftSideOfAnnotationString.split('\n');
                for (var k = 0; k < lines.length; k += 1) {
                    var line = lines[k];
                    _this.writePlainTextElementsForLineToArray(line, i.toString(), k, listOfObjects);
                }
                if (annotation.riskId.toString() === initialSelectedRiskId) {
                    annotation.isHighlightedText = true;
                }
                listOfObjects.push(<div id={annotation.riskId + "-" + elementId + "-div"}>
          <Annotation key={annotation.riskId + "-" + elementId + "-annotation"} AnnotationItem={annotation}/>
        </div>);
                startPosition = annotation.end;
            }
            var whatsLeftToRender = originalText.substr(startPosition, originalText.length);
            _this.writePlainText(whatsLeftToRender, listOfObjects);
            return listOfObjects;
        };
        _this.renderDocumentWithoutAnnotations = function (originalText) {
            var listOfObjects = [];
            var lines = originalText.split('\n');
            for (var k = 0; k < lines.length; k += 1) {
                var line = lines[k];
                _this.writePlainTextElementsForLineToArray(line, 'no-annotations', k, listOfObjects);
            }
            return listOfObjects;
        };
        _this.renderDocument = function () {
            var listOfObjects;
            var _a = _this.props, annotations = _a.annotations, originalText = _a.originalText;
            var elementId = _this.state.elementId;
            if (annotations.length === 0) {
                listOfObjects = _this.renderDocumentWithoutAnnotations(originalText);
            }
            else {
                listOfObjects = _this.renderAnnotations(annotations, originalText, elementId);
            }
            return listOfObjects;
        };
        _this.state = {
            groupBy: 'level',
            activeNodePath: undefined,
            openNodes: [],
            treeData: [],
            selectedRiskId: undefined,
            hasDoneInitialScroll: false,
            elementId: "ei-" + Math.random(),
        };
        return _this;
    }
    AnnotationViewer.prototype.render = function () {
        var _this = this;
        var _a = this.state, groupBy = _a.groupBy, activeNodePath = _a.activeNodePath, treeData = _a.treeData, openNodes = _a.openNodes, elementId = _a.elementId;
        var annotatedText = this.renderDocument();
        return (<div id={"annotation-container-" + elementId} className="annotation-container">
        <div key="annotation-left-pane" id={"left-pane-" + elementId} data-testid="annotations-left-pane" className="left-pane">
          <div id={"onchange-" + elementId} style={{ paddingBottom: '10px', paddingTop: '5px' }} onChange={this.onChangeValue}>
            <div className="annotation-radioButton" id={"annotation-radioButton-" + elementId}>
              <input type="radio" value="level" name="groupBy" checked={groupBy === 'level'} data-testid="annotations-groupby-radiobutton" id={"annotation-groupby-" + elementId} readOnly/>{' '}
              Risk
            </div>
            <div className="annotation-radioButton" id={"annotation-radioButton-" + elementId}>
              <input type="radio" value="group" name="groupBy" checked={groupBy === 'group'} data-testid="annotations-groupby-radiobutton" id={"annotations-groupby-radiobutton-" + elementId} readOnly/>{' '}
              Risk Group
            </div>
            <div className="annotation-radioButton" id={"annotation-radioButton-" + elementId}>
              <input type="radio" value="category" name="groupBy" checked={groupBy === 'category'} data-testid="annotations-groupby-radiobutton" id={"annotations-groupby-radiobutton-" + elementId} readOnly/>{' '}
              ConceptType
            </div>
          </div>
          <TreeMenu openNodes={openNodes} activeKey={activeNodePath} focusKey={activeNodePath} data={treeData} key={"tree-menu-" + elementId} onClickItem={function (_a) {
            var key = _a.key, label = _a.label, isOpen = _a.isOpen, type = _a.type, id = _a.id, props = __rest(_a, ["key", "label", "isOpen", "type", "id"]);
            _this.setActiveNode(key, type, isOpen, id);
        }}/>
        </div>

        <div key="annotation-right-pane" id={"right-pane-" + elementId} data-testid="annotations-right-pane" className="right-pane">
          {annotatedText}
        </div>
      </div>);
    };
    AnnotationViewer.getDerivedStateFromProps = function (props, state) {
        var groupBy = state.groupBy, openNodes = state.openNodes, activeNodePath = state.activeNodePath, selectedRiskId = state.selectedRiskId;
        var initialSelectedRiskId = props.initialSelectedRiskId;
        var treeData = AnnotationViewer.rebuildSidebar(groupBy, props);
        var newState = {
            treeData: treeData,
            openNodes: openNodes,
            activeNodePath: activeNodePath,
            selectedRiskId: selectedRiskId,
        };
        if (!activeNodePath && initialSelectedRiskId) {
            var initialActiveNodeGroup = treeData
                .map(function (x) { return x; })
                .find(function (x) { var _a; return (_a = x.nodes) === null || _a === void 0 ? void 0 : _a.find(function (y) { return y.key.toString() === initialSelectedRiskId; }); });
            var initialActiveNodePath = initialActiveNodeGroup
                ? initialActiveNodeGroup.key + "/" + initialSelectedRiskId
                : undefined;
            var initialOpenNodes = initialActiveNodeGroup
                ? [initialActiveNodeGroup.key]
                : [];
            newState.activeNodePath = initialActiveNodePath;
            newState.openNodes = initialOpenNodes;
        }
        if (!selectedRiskId && initialSelectedRiskId) {
            newState.selectedRiskId = initialSelectedRiskId;
        }
        return newState;
    };
    AnnotationViewer.createChildTreeNode = function (child) {
        var _a;
        return {
            key: child.riskId,
            label: (_a = child.chosenNormalisation) === null || _a === void 0 ? void 0 : _a.normalisationName,
            type: NodeType.child,
            id: child.riskId,
        };
    };
    AnnotationViewer.createTreeNode = function (group) {
        return {
            key: group.key,
            label: group.key,
            type: NodeType.parent,
            nodes: _.map(group.children, AnnotationViewer.createChildTreeNode),
        };
    };
    AnnotationViewer.rebuildSidebar = function (groupBy, props) {
        var annotations = props.annotations;
        var sidebarData = _.chain(annotations)
            .groupBy(groupBy)
            .map(function (value, key) { return ({ key: key, children: value }); })
            .map(AnnotationViewer.createTreeNode)
            .value();
        return sidebarData;
    };
    return AnnotationViewer;
}(React.Component));
export default AnnotationViewer;
