var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@chakra-ui/react';
import React from 'react';
import DataTable from './DataTable/DataTable';
import EditInput from './Cell/EditInput/EditInput';
import IndeterminateCheckbox from './Cell/IndeterminateCheckbox/IndeterminateCheckbox';
var Table = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (<Box as="table" sx={{
        tableLayout: 'auto',
        borderCollapse: 'collapse',
        width: '100%',
        backgroundColor: 'white',
    }} {...rest}>
    {children}
  </Box>);
};
var Thead = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (<Box as="thead" sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '1rem',
        textTransform: 'uppercase',
        backgroundColor: 'components.table.head',
        color: 'white',
    }} {...rest}>
    {children}
  </Box>);
};
var Tbody = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (<Box as="tbody" {...rest} sx={{ position: 'relative' }}>
    {children}
  </Box>);
};
var Tfoot = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (<Box as="tfoot" {...rest}>
    {children}
  </Box>);
};
var Tr = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (<Box as="tr" {...rest}>
    {children}
  </Box>);
};
var Th = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (<Box as="th" sx={{ padding: 2 }} {...rest}>
    {children}
  </Box>);
};
var Td = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    var style = rest.style;
    return (<Box as="td" sx={__assign({ padding: 2, border: '1px', borderWidth: '0 0 1px', borderColor: 'components.table.border' }, style)} {...rest}>
      {children}
    </Box>);
};
export { Table, DataTable, Thead, Tbody, Tfoot, Tr, Th, Td, EditInput, IndeterminateCheckbox, };
