/* eslint-disable-next-line import/prefer-default-export */
export var Sex;
(function (Sex) {
    Sex["MALE"] = "MALE";
    Sex["FEMALE"] = "FEMALE";
})(Sex || (Sex = {}));
export var Product;
(function (Product) {
    Product["LIFE"] = "Life";
    Product["CI"] = "CI";
})(Product || (Product = {}));
export var PolicyDocumentUploadStatus;
(function (PolicyDocumentUploadStatus) {
    PolicyDocumentUploadStatus["IN_PROGRESS"] = "IN_PROGRESS";
    PolicyDocumentUploadStatus["FULFILLED"] = "FULFILLED";
    PolicyDocumentUploadStatus["REJECTED"] = "REJECTED";
})(PolicyDocumentUploadStatus || (PolicyDocumentUploadStatus = {}));
export var MimeType;
(function (MimeType) {
    MimeType["TXT"] = "text/plain";
    MimeType["PDF"] = "application/pdf";
    MimeType["JPEG"] = "image/jpeg";
    MimeType["PNG"] = "image/png";
})(MimeType || (MimeType = {}));
