import { Select } from '@chakra-ui/react';
import React from 'react';
var Dropdown = function (_a) {
    var initialSelection = _a.initialSelection, handleSelect = _a.handleSelect, values = _a.values;
    var _b = React.useState(initialSelection), value = _b[0], setValue = _b[1];
    var handleSelectionChange = function (e) {
        setValue(e.target.value);
        handleSelect(e.target.value);
    };
    return (<Select value={value} onChange={handleSelectionChange}>
      {values.map(function (value) { return (<option key={value}>{value}</option>); })}
    </Select>);
};
export default Dropdown;
