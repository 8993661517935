import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
var SearchBox = function (_a) {
    var onSearch = _a.onSearch, placeholder = _a.placeholder;
    var _b = React.useState(''), searchText = _b[0], setSearchText = _b[1];
    var handleText = function (e) {
        if ((e.target.value.length < searchText.length &&
            e.target.value.length >= 3) ||
            e.target.value.length >= 3 ||
            e.target.value.length === 0) {
            onSearch(e.target.value);
        }
        setSearchText(e.target.value);
    };
    return (<InputGroup size="md" marginBottom={4}>
      <InputLeftElement pointerEvents="none" padding={3}>
        <FaSearch size="64" color="gray"/>
      </InputLeftElement>
      <Input value={searchText} placeholder={placeholder} onChange={handleText} sx={{
        backgroundColor: 'white',
    }}/>
    </InputGroup>);
};
export default SearchBox;
