import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import AppHeader from '@/components/Layout/AppHeader/AppHeader';
var AppLayout = function (_a) {
    var children = _a.children;
    return (<Flex sx={{
        flexDirection: 'column',
        mx: 'auto',
        // maxW: '1330px',
        h: '100vh',
    }}>
      <AppHeader />
      <Box sx={{
        // flex: 1,
        // w: '100%',
        // borderX: '1px',
        backgroundColor: 'brand.grey.50',
    }}>
        {children}
      </Box>
    </Flex>);
};
export default AppLayout;
