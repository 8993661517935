import React from 'react';
import { DEBOUNCE_DELAY } from '@/constants/app';
export default function useDebounce(value, delay) {
    if (delay === void 0) { delay = DEBOUNCE_DELAY; }
    var _a = React.useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    React.useEffect(function () {
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}
