import React from 'react';
import { VStack, Button, AlertDialog as ChakraAlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, Text, CloseButton, HStack, Center, } from '@chakra-ui/react';
import RadioOptions from './RadioOptions';
var FeedbackDialog = function (_a) {
    var isOpen = _a.isOpen, onCloseFunction = _a.onCloseFunction, primaryOnClick = _a.primaryOnClick, headerText = _a.headerText, bodyText = _a.bodyText, ctaText = _a.ctaText, radioButtonOptions = _a.radioButtonOptions;
    var _b = React.useState(''), selectedOption = _b[0], setSelectedOption = _b[1];
    var cancelRef = React.useRef(null);
    return (<ChakraAlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={function () {
        onCloseFunction();
        setSelectedOption('');
    }} closeOnOverlayClick={false} closeOnEsc>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <CloseButton data-testid="disagreement-close-button" ref={cancelRef} position="absolute" right="8px" top="8px" onClick={function () { return onCloseFunction(); }}/>
            <VStack>
              <Text sx={{
        fontSize: '30px',
        color: '#2D3849',
    }}>
                {headerText}
              </Text>
            </VStack>
          </AlertDialogHeader>
          <AlertDialogBody>
            {bodyText.map(function (item) { return (<Center key={item} style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {item}
              </Center>); })}
            <br />
            <RadioOptions options={radioButtonOptions} setSelectedOption={setSelectedOption}/>
            <HStack paddingTop={10} paddingBottom={8} justifyContent="center" mx="2em">
              <Button bg="brand.teal.900" color="white" width="100%" isDisabled={selectedOption === ''} onClick={function () {
        primaryOnClick(selectedOption);
        onCloseFunction();
        setSelectedOption('');
    }}>
                {ctaText}
              </Button>
            </HStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>);
};
export default FeedbackDialog;
