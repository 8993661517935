/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import React from 'react';
import { makeQueryParams, makeSortParamValue, } from '@/libs/urlParams/urlParams';
import { listPoliciesAndRun } from '@/domain/Misrep/MisrepService';
export default function useListPoliciesAndRun(_a) {
    var _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.sortBy, sortBy = _c === void 0 ? [] : _c, _d = _a.tenant, tenant = _d === void 0 ? '' : _d, _e = _a.token, token = _e === void 0 ? '' : _e, _f = _a.searchText, searchText = _f === void 0 ? '' : _f;
    var params = makeQueryParams({
        page: "" + page,
        sort: makeSortParamValue(sortBy),
        size: 20,
        searchText: searchText,
    });
    var debouncedRefetchRuns = React.useCallback(function () {
        return new Promise(function (resolve, reject) {
            var debounced = debounce(function () {
                listPoliciesAndRun({ params: params, tenant: tenant, token: token })
                    .then(resolve)
                    .catch(reject);
            }, 2500);
            debounced();
        });
    }, [params, tenant, token]);
    return useQuery(['policies', { page: page, sortBy: sortBy, tenant: tenant }], debouncedRefetchRuns, {
        keepPreviousData: true,
        retry: false,
        refetchInterval: 30000,
    });
}
