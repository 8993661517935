import React from 'react';
import { useHistory } from 'react-router-dom';
import { setupInterceptors } from '../axiosConfig';
function InjectAxiosInterceptors() {
    var history = useHistory();
    React.useEffect(function () {
        setupInterceptors(history);
    }, [history]);
    return null;
}
export default InjectAxiosInterceptors;
