import React from 'react';
import { Box, Flex, Progress, Text } from '@chakra-ui/react';
import { FaCheckCircle, FaExclamationCircle, FaFileAlt } from 'react-icons/fa';
import { PolicyDocumentUploadStatus } from '@/domain/Policy/Policy';
var getStatus = function (progress) { return ({
    IN_PROGRESS: (<Text sx={{
        fontSize: 'sm',
        fontWeight: 'bold',
    }}>
      {progress}%
    </Text>),
    FULFILLED: (<Box as={FaCheckCircle} color="brand.status.success" data-testid={PolicyDocumentUploadStatus.FULFILLED}/>),
    REJECTED: (<Box as={FaExclamationCircle} color="brand.status.error" data-testid={PolicyDocumentUploadStatus.REJECTED}/>),
}); };
var UploadState = function (_a) {
    var _b = _a.status, status = _b === void 0 ? PolicyDocumentUploadStatus.IN_PROGRESS : _b, _c = _a.progress, progress = _c === void 0 ? 0 : _c, _d = _a.filename, filename = _d === void 0 ? '' : _d;
    var progressColorScheme = status === PolicyDocumentUploadStatus.IN_PROGRESS
        ? 'brand.blue'
        : status === PolicyDocumentUploadStatus.REJECTED
            ? 'brand.red'
            : 'brand.green';
    return (<Flex sx={{ w: '100%' }} bg="gray.50">
      <Box as={FaFileAlt} boxSize="2.5rem" data-testid="mimeType"/>
      <Flex sx={{ flexDirection: 'column', flex: 1 }}>
        <Text sx={{
        fontSize: 'sm',
        fontWeight: 'bold',
    }}>
          {filename}
        </Text>
        <Progress value={status === PolicyDocumentUploadStatus.FULFILLED ||
        status === PolicyDocumentUploadStatus.REJECTED
        ? 100
        : progress} hasStripe colorScheme={progressColorScheme} size="lg" sx={{ borderRadius: '.2rem' }}/>
      </Flex>
      <Flex sx={{
        alignItems: 'center',
        mt: 4,
        ml: 2,
    }}>
        {getStatus(progress)[status]}
      </Flex>
    </Flex>);
};
export default UploadState;
