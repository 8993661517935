/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormControl, FormLabel, Input, Center, InputGroup, InputRightElement, Radio, RadioGroup, VStack, Stack, Heading, Box, Divider, Tabs, TabPanels, Tab, TabList, TabPanel, } from '@chakra-ui/react';
import { FastField, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
var ApplicationDataForm = function (_a) {
    var policyAppForm = _a.policyAppForm, formValues = _a.formValues, formikRef = _a.formikRef;
    var t = useTranslation([
        'policy-management/create',
        'policy-management/appform',
    ]).t;
    var handleChange = function (event, form, field) {
        var newValue = event.target.value;
        newValue = newValue.replace(/[^0-9.]/g, '');
        var decimalIndex = newValue.indexOf('.');
        if (decimalIndex !== -1) {
            var decimalPart = newValue.substring(decimalIndex + 1);
            if (decimalPart.length > 2) {
                newValue = newValue.substring(0, decimalIndex + 3);
            }
        }
        form.setFieldValue(field.name, newValue);
    };
    return (<>
      <Heading as="h1" variant="page-header">
        <Center> {t('common:APP_FORM')} </Center>
      </Heading>
      <Center sx={{ height: '50px' }}> </Center>

      <Formik initialValues={formValues} onSubmit={function () { }} innerRef={formikRef}>
        {function () { return (<Form>
            <Center>
              <Tabs isLazy variant="unstyled" orientation="vertical" paddingRight="5%">
                <TabList>
                  {policyAppForm.groupedQuestions.map(function (group) { return (<Tab key={group.groupName} bg="transparent" color="black" _selected={{
        bg: 'black',
        color: 'white',
        borderBottom: '2px solid',
    }}>
                      {group.groupName}
                    </Tab>); })}
                </TabList>
                <TabPanels>
                  {policyAppForm.groupedQuestions.map(function (group) { return (<TabPanel key={group.groupName} backgroundColor="clear">
                      {group.questions.map(function (question) { return (<VStack key={"vstack-" + question.id} align="center" spacing={5} marginBottom={5}>
                          <Box boxShadow="base" rounded="md" backgroundColor="white" overflow="hidden" p={4} width="600px">
                            <FastField key={"field-" + question.id} name={question.id}>
                              {function (_a) {
        var field = _a.field, form = _a.form;
        return (<FormControl id={question.id} mb={4}>
                                  <FormLabel paddingBottom={5} htmlFor={question.id}>
                                    {question.question}
                                  </FormLabel>
                                  <Divider variant="dashed"/>
                                  {question.answerType === 'Boolean' ? (<RadioGroup {...field} defaultValue onChange={function (val) {
            return form.setFieldValue(field.name, val);
        }}>
                                      <Stack direction="row" alignItems="left" ml={2} paddingTop={5}>
                                        <Radio value="true" colorScheme="brand.blue">
                                          {t('policy-management/appform:FORM.LABEL.RADIO.TRUE')}
                                        </Radio>
                                        <Radio value="false" colorScheme="brand.blue">
                                          {t('policy-management/appform:FORM.LABEL.RADIO.FALSE')}
                                        </Radio>
                                      </Stack>
                                    </RadioGroup>) : (<InputGroup paddingTop={5}>
                                      <Input {...field} onChange={function (event) {
            return handleChange(event, form, field);
        }} type="number" step="0.01" data-testid="numberInputField" backgroundColor="white"/>
                                      <InputRightElement paddingRight={0}/>
                                    </InputGroup>)}
                                </FormControl>);
    }}
                            </FastField>
                          </Box>
                        </VStack>); })}
                    </TabPanel>); })}
                </TabPanels>
              </Tabs>
            </Center>
          </Form>); }}
      </Formik>
    </>);
};
export default ApplicationDataForm;
